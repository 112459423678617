import React, { useState } from 'react';
import 'react-dates/initialize';
import {
  DropdownToggle, DropdownMenu, 
  ButtonDropdown, Card, CardHeader, CardBody, 
} from 'reactstrap';
import {
  MdDone,
} from 'react-icons/md';
import {
  VscFilter,
} from 'react-icons/vsc';


const MonitorFilter = ({ statusEnum, configCard, onConfigChange }) => {
  const [config, setConfig] = useState(configCard)
  const [popoverOpen, setPopoverOpen] = useState(false)

  const toggle = () => {
    setPopoverOpen(!popoverOpen)
    popoverOpen && onConfigChange && onConfigChange(config)
  };
  
  const toggleItem = (id) => {
    if (id >= 0) {
      // add/remove item in config
      let a = Array.isArray(config && config.statuses) ? config.statuses : []
      const index = a.indexOf(id)
      if (index > -1) a.splice(index, 1)
      else            a.push(id)
      // save changes
      setConfig({statuses: a})
    }
  }

  return (
    (config &&
      <React.Fragment>
        <ButtonDropdown isOpen={popoverOpen} toggle={toggle} className="align-top ml-2 d-inline float-right">
          <DropdownToggle size="sm" className="p-0 btn btn-transparent" title="Filter">
            <VscFilter size={16} />
          </DropdownToggle>
          <DropdownMenu right className="p-2">
            <Card className="text-white bg-dark mb-0">
              <CardHeader>
                Status filter
              </CardHeader>
              <CardBody className="py-2">
                {statusEnum && Object.keys(statusEnum).map(key => {
                  const id = statusEnum[key]
                  const selected = (config.statuses || []).includes(id)
                  if (id <= 0) return (null);
                  return (<div key={"cam-stat-" + id} className="text-nowrap pointer" onClick={toggleItem.bind(this, id)}>
                            <MdDone size={16} className={"mb-1 " + (selected ? ":" : "invisible")}/>
                            <span className={"ml-1 badge " + (selected ? " badge-warning " : " badge-dark text-muted ")} title={id + " - " + key}>{key}</span>
                          </div>)
                })}
              </CardBody>
            </Card>
          </DropdownMenu>
        </ButtonDropdown>
      </React.Fragment>
    )
  )
}

export default MonitorFilter;
