import moment from 'moment';
import 'moment-timezone';
import _ from "lodash"
import {
  MdFavorite,
  MdFavoriteBorder,
  MdErrorOutline,
  MdVisibilityOff,
  MdLocalShipping,
} from 'react-icons/md';
import { FaHeartBroken } from 'react-icons/fa';
import CAMERA_OFFLINE from "../assets/img/CameraOffline.jpg"
const { Permissions, Resources } = require('./roles')

const TEMP_RANGE_MOD = { min: -20, max: 65 };
const TEMP_RANGE_RX = { min: -20, max: 65 };
const TEMP_RANGE_WEATHER = { min: -20, max: 45 };
const TEMP_RANGE_DELTA = { min: 0, max: 32 };
const TEMP_RANGE_PC = { min: 0, max: 70 };
const TEMP_RANGE_RTR = { min: 0, max: 70 };
const BRIGHTNESS_RANGE = { min: 0, max: 130000 };
const MAX_TEMPERATURE_HISTORY_IN_YEARS = 2
const TEMPERATURE_MONITOR_STATUS = {
  "OK": 0,          // No issue (OK)
  "TEMP_EXTREME": 1,// Very High or Low temperature of Module, Receiver or Weather
  "HIGH_DELTA": 2,  // High temperature differential between the Receiver Temperature and the Weather Temperature
}
const TIMEZONE_MONITOR_STATUS = {
  "OK": 0,              // No issue (OK)
  "INVALID_TIMEZONE": 1,// Invalid Time Zone
}
const ONLINE_STATUS = {
  "OFFLINE": 0,
  "ONLINE": 1,
}
const SNAPSHOT_STATUS = {
  ERROR_S3_INVALID_RESPONSE: -1,
  SNAPSHOT_OK: 0,
  SNAPSHOT_NOT_FOUND: 1,
  SNAPSHOT_IS_OUTDATED: 2,
  FILE_SIZE_SMALL: 3,
  FILE_SIZE_BIG: 4
}
const CAMERA_MONITOR_STATUS = {
  "BOOTUP":              -4,  // Bootup/reboot
  "PAUSED":              -3,  // Paused
  "DISABLED":            -2,  // Disable Camera Monitor
  "FIRST_TIME_BOOTUP":   -1,  // When the app is started for the first time

  // detection status
  "OK":                   0,  // No issue (OK)
  "ISSUE_DETECTED":       1,  // One time issue detected
  "ISSUE_INTERMITTENT":   2,  // Intermittent issue detected 

  // Hardware error
  "ERR_CAM_NOT_FOUND":    3,  // Can't reach camera 
  "ERR_INVALID_MODEL":    4,  // Invalid Model
  "ERR_INVALID_FIRMWARE": 5,  // Invalid Firmware
  "ERR_CAM_NOT_AUTH":     6,  // Unauthorized access to the camera (invalid usr/pwd or authentication type)

  // ACAP errors
  "ACAP_INSTALL_ERROR":   7,  // Can't install the ACAP
  "ACAP_RUN_ERROR":       8,  // Can't run ACAP or it's not analyzing 
  "ACAP_DISABLED":        9,  // ACAP is disabled
  "ACAP_NOT_CONFIGURED": 10,  // ACAP is not configured (Region Of Interest is not defined)
}
const CAMERA_MONITOR_STATUS_DEFAULT = [1,2]
class ALERT_LIFECYCLE {
  constructor(val, text) { this.val = val; this.text = text }
  static OpenWait = new ALERT_LIFECYCLE(1, "OpenWait")
  static Firing = new ALERT_LIFECYCLE(2, "Firing")
  static CloseWait = new ALERT_LIFECYCLE(3, "CloseWait")
  static Closed = new ALERT_LIFECYCLE(4, "Closed")
}
class ALERT_SEVERITY {
  constructor(val, text) { this.val = val; this.text = text }
  static Critical = new ALERT_SEVERITY(1, "Critical")
  static Error = new ALERT_SEVERITY(2, "Error")
  static Failover = new ALERT_SEVERITY(3, "Failover")
  static Warning = new ALERT_SEVERITY(4, "Warning")
}
class ALERT_TYPE {
  constructor(val, text) { this.val = val; this.text = text }
  static State = new ALERT_TYPE(1, "State")
  static BrightnessSync = new ALERT_TYPE(2, "BrightnessSync")
  static BrightnessControl = new ALERT_TYPE(3, "BrightnessControl")
  static AbsTemperature = new ALERT_TYPE(4, "AbsoluteTemperature")
  static DiffTemperature = new ALERT_TYPE(5, "DifferentialTemperature")
}
class ALERT_STATE {
  constructor(val, text) { this.val = val; this.text = text }
  static Online = new ALERT_STATE(1, "online")
  static Failover = new ALERT_STATE(2, "failover")
  static Offline = new ALERT_STATE(3, "offline")
  static Unknown = new ALERT_STATE(4, "unknown")
  static Absent = new ALERT_STATE(5, "absent")
  static Error = new ALERT_STATE(6, "error")
  static NotMonitored = new ALERT_STATE(7, "not-monitored")
  static NotInstalled = new ALERT_STATE(8, "not-installed")
}
class ALERT_ITEM_TYPE {
  constructor(val, text) { this.val = val; this.text = text }
  static Module = new ALERT_ITEM_TYPE(1, "Module")
  static Receiver = new ALERT_ITEM_TYPE(2, "Receiver")
  static Port = new ALERT_ITEM_TYPE(3, "Port")
  static Display = new ALERT_ITEM_TYPE(4, "Display")
}
class ALERT_STATUS {
  static Type = ALERT_TYPE            // Type,
  static Severity = ALERT_SEVERITY    // Severity,
  static ItemType = ALERT_ITEM_TYPE   // ItemType,
  static Lifecycle = ALERT_LIFECYCLE  // Lifecycle,
  static SemanticState = ALERT_STATE  // SemanticState,

  static getAll() {
    const all = {}
    for (const [, o] of Object.entries(ALERT_STATUS)) {
      let items = new Set()
      for (const [, item] of Object.entries(o)) {
        items.add(item.val)
      }
      if (items.size)
        all[o.name] = items
    }
    // console.log(JSON.stringify(all,  (_key, value) => (value instanceof Set ? [...value] : value)))
    return all
  }
  static getDefault() {
    let r = ALERT_STATUS.getAll()
    r[ALERT_SEVERITY.name].delete(ALERT_SEVERITY.Failover.val)
    return r
  }
}
const MAX_ALERT_HISTORY_IN_DAYS = 30

function getEnumText(key, id) {
  if (!key || id === null) return null

  const enumKey = Object.keys(ALERT_STATUS)
    .find(k => ALERT_STATUS[k].name === key)
  if (!enumKey) return null

  let enumClass = ALERT_STATUS[enumKey]
  if (!enumClass) return null

  const foundKey = Object.keys(enumClass)
    .find(k => enumClass[k].val === id)
  if (!foundKey && !enumClass[foundKey]) return null

  const txt = enumClass[foundKey].text
  return txt
}

function readableBytes(bytes) {
  bytes = Number(bytes)
  if (!_.isFinite(bytes)) return ""
  if (bytes === 0) return 0

  let i = Math.floor(Math.log(bytes) / Math.log(1024))
  let sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  if ((bytes / Math.pow(1024, i)) >= 500) { i++ }
  return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + sizes[i]
};

function readableKB(bytes) {
  return (bytes / 1024).toFixed(2) * 1 + "KB"
};

function readableNumber(n, decimals = 0) {
  n = Number(n)
  if (!_.isFinite(n)) return ""
  if (n === 0) return 0

  let i = Math.floor(Math.log(n) / Math.log(1000))
  let sizes = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y']
  if ((n / Math.pow(1000, i)) >= 500) { i++ }
  return (n / Math.pow(1000, i)).toFixed(decimals) * 1 + sizes[i]
};

function formatedNumber(n, locals = "en-US") {
  n = Number(n)
  if (!_.isFinite(n)) return ""
  return n.toLocaleString(locals)
}

function getImageDateTime(timeStamp) {
  const FORMAT = 'MMMM Do YYYY, HH:mm'
  return moment(timeStamp).format(FORMAT)
};

function getUser(user) {
  user = user || JSON.parse(localStorage.getItem("user")) || {}
  user.payload = _parseJwt(user.token)
  user.refreshPayload = _parseJwt(user.refreshToken)
  user.permissions = new Permissions(user.payload)
  return user
};

function _parseJwt(token) {
  if (!token) { return null }
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace('-', '+').replace('_', '/');
  const payload = JSON.parse(window.atob(base64));
  // decode displays array from binary to int
  payload.displays = displays_decode(payload)
  return payload
};

function displays_encode(displays, skip_optimization = false, force_optimization = false) {
  // convert array of Display IDs into a binary array [0..max(ID)] which is then encoded into 8bit array
  //  - create binary array  of 1 bit (with default value 0) for each number between 1 and max ID in data     
  //  - for each ID in the array set the bit to 1
  //  - convert binary array to 8bit numberic array 
  //  - return resulting 8bit numberic array

  if (!displays || !Array.isArray(displays)) return null;
  const maxId = displays.reduce((o,n)=>Math.max(o,n))
  const binEncodeIsOptimal = maxId < (displays.length * 8)   // max(ID) < count * 8
  const MIN_THRESHOLD_COUNT = 600;  // default should be 100; use 600 until we fix all APIs to handle `display_encoded`
  const skip = (skip_optimization || displays.length < MIN_THRESHOLD_COUNT || !binEncodeIsOptimal)
  if (skip && !force_optimization)
      return {displays}
  
  // - convert number array to binary array
  let binArr = []
  let binArrStr = ""
  displays.forEach(id => binArr[id] = "1")
  for (let i = 0; i < binArr.length; i++) {
      binArrStr += binArr[i] || "0"
  }
  let padLen = (binArrStr.length % 8 === 0) ? 0 : binArrStr.length + (8 - binArrStr.length % 8)
  binArrStr = binArrStr.padEnd(padLen, "0")

  // convert binary array to bytes array
  let arrBytes = []
  for (let i = 0; i < binArrStr.length; i++) {
      const binStr = binArrStr.substring(i, i+8)
      const code = parseInt(binStr, 2)
      arrBytes.push(code)
      i += (8-1)
  }

  return {displays_encoded:  arrBytes}
}

function displays_decode(payload) {    
  // convert base64 encoded binary array into a number array of Display IDs 
  if (!payload) return null;
  if (payload.displays && Array.isArray(payload.displays) && payload.displays.length) return payload.displays;  
  if(!payload.displays_encoded || !Array.isArray(payload.displays_encoded) || !payload.displays_encoded.length) 
    return null;

  // convert char to binary
  let bits = ""
  for (let i = 0; i < payload.displays_encoded.length; i++) {
      bits += payload.displays_encoded[i].toString(2).padStart(8,"0");        
  }

  // convert binary to an array of IDs
  let ids = []
  for (let i = 0; i < bits.length; i++) {
      if (bits[i]==="1")
          ids.push(i)
  }

  return ids
}

function getBrowserFullscreenElementProp() {
  if (typeof document.fullscreenElement !== "undefined") {
    return "fullscreenElement";
  } else if (typeof document.mozFullScreenElement !== "undefined") {
    return "mozFullScreenElement";
  } else if (typeof document.msFullscreenElement !== "undefined") {
    return "msFullscreenElement";
  } else if (typeof document.webkitFullscreenElement !== "undefined") {
    return "webkitFullscreenElement";
  } else {
    return null
  }
}

function logProgress(val, maxVal) {
  val = Math.min(val, maxVal)
  return Math.log(val + 1) * 100 / Math.log(maxVal + 1)
}
function timeAgo(date, MAX_VAL = 31 * 24, unitOfTime = 'hours') { // 744  - max 1 month before alert is deleted from DB
  let r = {
    description: (typeof date === 'undefined' ? "" : "n/a"),
    percent: 0,
    title: "",
  }

  if (date instanceof moment) {
    const diff = (date) && moment().diff(date, unitOfTime)

    r = {
      description: date.fromNow(),
      percent: logProgress(diff, MAX_VAL),
      title: date.format("YYYY-MM-DD H:mm:ss z"),
      minutes: diff
    }
  }

  return r
}

function getSnapUrl(snap_path) {
  if (snap_path) {
    snap_path = snap_path.startsWith("/") ? snap_path : `/${snap_path}`
    return `https://s3.us-east-2.amazonaws.com/mri-cameras${snap_path}`
  }
  return null
}

function showNoImage(ev) {
  if (CAMERA_OFFLINE && !ev.target.classList.contains("no-img")) {
    ev.target.src = CAMERA_OFFLINE
    ev.target.alt = "No image"
    ev.target.classList.remove("no-img")
    ev.target.classList.add("no-img")
    ev.stopPropagation()
  }
}

function isEmpty(o) {
  for (let i in o) return false; return true;    // fastest way
}

function toBool(val, defVal = false) {
  if (val) {
    switch (val.toLowerCase()) {
      case "true":
      case "1":
        return true;
      case "false":
      case "0":
        return false;
      case "null":
        return null;
      default:
        return defVal;
    }
  }
  return defVal
}

function enumToString(enumObj, value) {
  if (!enumObj) {
    return (typeof value === "string") ? value : null
  }
  return (Object.keys(enumObj).find(e => enumObj[e] === value) || "").toLowerCase()
}

function getDisplayStatusColor(displayStatus, displayVOP) {
  let Icon = MdErrorOutline
  let textColor = " text-info"
  let badgeColor = "info"

  switch (displayStatus) {
    case "not-monitored":
      Icon = MdVisibilityOff
      textColor = " text-primary"
      badgeColor = "primary"
      break
    case "not-installed":
      Icon = MdLocalShipping
      textColor = " text-secondary"
      badgeColor = "secondary"
      break
    case "online":
      Icon = (!displayVOP || displayVOP === "nil" || displayVOP === "100.0") ? MdFavorite : FaHeartBroken
      textColor = " text-success"
      badgeColor = "success"
      break
    case "offline":
      Icon = MdFavoriteBorder
      textColor = " text-danger"
      // cardClass = " board-danger "
      // cardBg = " bg-danger "
      badgeColor = "danger"
      break
    default:
      break
  }

  return [
    Icon,
    textColor,
    badgeColor,
  ]
}

function Round(number, decimalPlaces = 1) {
  const factorOfTen = Math.pow(10, decimalPlaces)
  return Math.round(number * factorOfTen) / factorOfTen
}

function RoundPrecise(number, decimalPlaces = 2) {
  return Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces)
}

function marshallTZStat(d) {
  return {
    StatsTZ: {
      UpdatedAt: ((d.Status && d.Status.Lastbeat) || null),
      Status: TIMEZONE_MONITOR_STATUS.INVALID_TIMEZONE,
    },
  }
}
function marshallPingStat(d, p) {
  return {
    StatsPing: {
      UpdatedAt: ((d.Status && d.Status.Lastbeat) || null),
      Status: ONLINE_STATUS.OFFLINE,
      Stats: p,
    },
  }
}
function marshallTempStat(d, c = false) {
  if (!d) return null
  c && console.log("# MARSHAL:", d)

  const modTemp = (d && d.Status && d.Status.Temperature && d.Status.Temperature.Average) || null
  const rxTemp = (d && d.Status && d.Status.TemperatureRx && d.Status.TemperatureRx.Average) || null
  const weatherTemp = (d && d.Weather && d.Weather.Temp) || null
  const delta = Math.abs(rxTemp - (weatherTemp > 0 ? weatherTemp : 0))
  const lastbeat = (d && d.Status && d.Status.Lastbeat) || null
  const brightness = (d && d.Status && d.Status.BrightnessControl && d.Status.BrightnessControl.ControlInput) || null
  const pcTemp = (d && d.MonitorStatus && d.MonitorStatus.TemperaturePC) || null
  const rtrTemp = (d && d.MonitorStatus && d.MonitorStatus.TemperatureRtr) || null

  const stats = {
    TempDelta: { Value: delta, Extreme: isExtreme(delta, TEMP_RANGE_DELTA) },
    TempModule: { Value: modTemp, Extreme: isExtreme(modTemp, TEMP_RANGE_MOD) },
    TempReceiver: { Value: rxTemp, Extreme: isExtreme(rxTemp, TEMP_RANGE_RX) },
    TempWeather: { Value: weatherTemp, Extreme: isExtreme(weatherTemp, TEMP_RANGE_WEATHER) },
    TempPC: { Value: pcTemp, Extreme: isExtreme(pcTemp, TEMP_RANGE_PC) },
    TempRtr: { Value: rtrTemp, Extreme: isExtreme(rtrTemp, TEMP_RANGE_RTR) },
    Brightness: { Value: brightness, Extreme: isExtreme(brightness, BRIGHTNESS_RANGE) },
  }

  let status = TEMPERATURE_MONITOR_STATUS.OK
  if (stats.TempDelta.Extreme) {
    status = TEMPERATURE_MONITOR_STATUS.HIGH_DELTA
  } else if (stats.TempModule.Extreme || stats.TempReceiver.Extreme || stats.TempWeather.Extreme ||
    stats.TempPC.Extreme || stats.TempRtr.Extreme || stats.Brightness.Extreme) {
    status = TEMPERATURE_MONITOR_STATUS.TEMP_EXTREME
  }

  return {
    StatsTemperature: {
      UpdatedAt: lastbeat,
      Status: status,
      Stats: stats,
    },
  }
}
function marshallOfflineStat(d) {
  return {
    StatsOffline: {
      UpdatedAt: ((d.Status && d.Status.Lastbeat) || null),
      Status: ONLINE_STATUS.OFFLINE,
    },
  }
}
function addMonitorTemps(d, pcData, rtrData) {
  return Object.assign(d, {
    MonitorStatus: {
      TemperaturePC: _.get(pcData.get(d.DisplayId), "Stats.CPU_temp"),
      TemperatureRtr: parseFloat(_.get(rtrData.get(d.DisplayId), "Stats.267"))
    }
  })
}
function getMonitorMap(stats) {
  // convert array to map 
  const map = new Map()
  if (stats && stats.length) {
    for (let i = stats.length; i--;) {
      const displayId = _.toInteger(stats[i].AppKey.split("-")[2])
      map.set(displayId, stats[i])
    }
  }
  return map
}
function isExtreme(val, range) {
  return (!val || isNaN(val) || !range)
    ? false
    : val < range.min || val > range.max
}

function textMultiline(input) {
  return (input || "").split("\\n").join("\n")
}
function htmlDecode(input) {
  var e = document.createElement('div');
  e.innerHTML = input;
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

//#region Chart helper functions ----------------
const PC_INTERVAL_IN_MIN = 35       // 35 min
const DATA_INTERVAL_IN_MIN = 5      //  5 min
const ROUTER_INTERVAL_IN_MIN = 30   // 60 min
const WEATHER_INTERVAL_IN_MIN = 65  // 65 min
const FORMAT_DATE_TIME = "YYYY-MM-DD H:mm:ss"

function allowTemperature(user) {
  return (
    _.get(user, "payload.role") === "admin"
    || ["bose_user", "manderson"].includes(_.get(user, "payload.username"))
  )
}

function getPointsRange(min, max, precision_in_sec, format_date_time, timeZone = "UTC") {
  min = (min && min.unix()) || moment.tz(timeZone).startOf("day").unix()
  max = (max && max.unix()) || moment.tz(timeZone).endOf("day").unix()
  precision_in_sec = precision_in_sec || DATA_INTERVAL_IN_MIN * 60
  format_date_time = format_date_time || "YYYY-MM-DD H:mm:ss"

  let minTS = moment.tz(min * 1000, timeZone).startOf('day').unix()   // 00:00:00 in seconds
  let maxTS = moment.tz(max * 1000, timeZone).endOf('day').unix()     // 23:59:59 in seconds
  let points = Math.ceil((maxTS - minTS) / precision_in_sec)

  return { minTS, maxTS, precision_in_sec, format_date_time, points }
}

function getLabelSets(min, max, precisionInSec, formatDateDime, timeZone = "UTC") {
  const { minTS, precision_in_sec, format_date_time, points } = getPointsRange(min, max, precisionInSec, formatDateDime, timeZone)
  let labels = new Array(points)
  for (let i = 0; i < labels.length; i++) {
    labels[i] = moment.tz((minTS + i * precision_in_sec) * 1000, timeZone).format(format_date_time)
  }
  return labels
}

const unixUtcToTzStr = (val, timeZone) => timeZone ? moment.utc(val).tz(timeZone).format(FORMAT_DATE_TIME) : null

function extrapolateToLabelPoints(history, labels, timeZone, exclude = []) {
  // DESCRIPTION: Creates (average/extrapolate) dataset point for each label data point 
  // SCENARIOS:
  //    too many DS points inside label tick slot
  //    too little DS points inside label tick slot
  //    same DS points inside label tick slot
  //    shifted DS points inside label tick slot
  // NOTE:
  //    Labels are in Local TimeZone
  //    history is in UTC             (FTD: set everything to UTC and only change the displayed date times to appropriate time zone)
  // ----------------------------------------------------
  if (!labels || !labels.length || !history || !timeZone) { return null }

  let ds = {} // resulting Dataset

  const utcToLoc = (val) => moment.utc(val, FORMAT_DATE_TIME).tz(timeZone)
  const locToLoc = (val) => moment.tz(val, FORMAT_DATE_TIME, timeZone)

  // for each Label
  for (let lblIndex = 0; lblIndex < labels.length; lblIndex++) {
    const max = locToLoc(labels[lblIndex])

    // for each Dataset
    for (const key in history) {
      if (history.hasOwnProperty(key) && !exclude.includes(key)) {
        let series = history[key]
        if (!ds[key]) { ds[key] = { idx: 0, data: [] } }
        if (key === "lx" && !ds["brt"]) { ds["brt"] = { idx: 0, data: [] } } // LX contains Photocell and Brightness values => create a seperate dataset for Brightness

        let sum = 0
        let sumBrt = 0
        let count = 0
        let d = ds[key]

        // skip blank series OR existing/extrapolated labels OR processed series
        if (isEmpty(series) || d.data.length > lblIndex) { continue; }

        // add blank points
        if (d.idx >= series.length) {
          d.data[lblIndex] = {
            x: locToLoc(labels[lblIndex]).format(FORMAT_DATE_TIME),
            y: NaN,
          }
          if (key === "lx") { ds["brt"].data[lblIndex] = d.data[lblIndex] }
          continue  // go to next point
        }

        // average items (sum all series items before current Label Tick)
        for (; d.idx < series.length && utcToLoc(series[d.idx].ts).isSameOrBefore(max); d.idx++) {
          sum += series[d.idx].val
          sumBrt += series[d.idx].out
          count++
        }

        if (count > 0) {
          // average item
          d.data[lblIndex] = {
            x: locToLoc(labels[lblIndex]).format(FORMAT_DATE_TIME),
            y: (count === 1) ? sum : Math.round(sum / count * 10) / 10,
            // avg: count > 1
          }
          if (key === "lx") {
            ds["brt"].data[lblIndex] = {
              x: locToLoc(labels[lblIndex]).format(FORMAT_DATE_TIME),
              y: (count === 1) ? sumBrt : Math.round(sumBrt / count * 10) / 10,
            }
          }
        } else {
          // extrapolate items
          const maxInterval = (key === "rtr") ? ROUTER_INTERVAL_IN_MIN
            : (key === "wx") ? WEATHER_INTERVAL_IN_MIN
              : (key === "pc") ? PC_INTERVAL_IN_MIN : DATA_INTERVAL_IN_MIN
          const deltaY = d.idx > 0 ? (series[d.idx].val - series[d.idx - 1].val) || null : null; // valid delta number or null
          const dTS = deltaY === null ? null : utcToLoc(series[d.idx].ts)
          const prevTS = deltaY === null ? null : utcToLoc(series[d.idx - 1].ts)
          const deltaX = deltaY === null ? null : dTS.diff(prevTS, "minutes")
          const extrap = deltaY === null ? null : ((lblIndex && d.data[lblIndex - 1] && d.data[lblIndex - 1].y) || null) !== null && deltaX <= maxInterval // extrapolate when previous number was not NULL AND delta is < maxInterval

          for (let ex = lblIndex, tick = max; ex < labels.length && tick.isBefore(dTS); ex++, tick = locToLoc(labels[ex])) {
            // y' = x' * y/x
            const val = !extrap ? null : Math.round((series[d.idx - 1].val + tick.diff(prevTS, "minutes") * deltaY / deltaX) * 100) / 100
            d.data[ex] = {
              x: locToLoc(labels[ex]).format(FORMAT_DATE_TIME),
              y: val,
            }

            if (key === "lx") {
              const val = !extrap ? null : Math.round((series[d.idx - 1].out + tick.diff(prevTS, "minutes") * deltaY / deltaX) * 100) / 100
              ds["brt"].data[ex] = {
                x: locToLoc(labels[ex]).format(FORMAT_DATE_TIME),
                y: val,
              }
            }
          }
        }
      }
    }
  }

  return ds
}
//#endregion Chart helper functions ----------------

function getProps(obj) {
  if (!obj) return null;
  let retVal = [];
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      retVal.push(obj[prop])
    }
  }
  return retVal;
}

const fetchTimeout = (url, ms, { signal, ...options } = {}) => {
  const controller = new window.AbortController()
  const promise = fetch(url, { signal: controller.signal, ...options });
  if (signal) signal.addEventListener("abort", () => controller.abort());
  const timeout = setTimeout(() => controller.abort(), ms);
  return promise.finally(() => clearTimeout(timeout));
};

function parseQueryString(qs) {
  if (!qs || typeof qs !== 'string') return qs
  if (qs[0] === "?") qs = qs.slice(1)

  return qs.split("&")
    .reduce(
      (memo, param) => {
        const kp = param.split("=")
        return {
          ...memo,
          [kp[0]]: kp[1]
        }
      },
      {}
    )
}

function onCreateTicket(o, e, c) {
  if(!o) return false;
  
  e.stopPropagation()

  // subject format: "Issue report: [PortalID][Company][Project][Serial Number]"

  var toEmail = "service@mediaresources.com"
  var ccEmail = "adavidson@mediaresources.com"
  var id = o.DisplayId || o.display_id || ""
  var company = o.CompanyName || o.company || ""
  var project = o.ProjectName || o.project_name || ""
  var display = o.DisplayName || o.display_name || ""
  var sn = o.SerialNumber || o.sn || ""
  var subject = "Issue report: "
    + id
    + (company && (" - " + company))
    + (project && (" - " + project))
    + (display && (" - " + display))
    + (sn && (" - " + sn))
  var emlBody = "Please describe the issue."

  document.location.href = "mailto:" + toEmail
    + "?cc=" + encodeURIComponent(ccEmail)
    + "&subject=" + encodeURIComponent(subject)
    + "&body=" + encodeURIComponent(emlBody);
}

function takeVideoSnapshot(id, fileName = 'snapshot.png') {
  // find video element
  var video = document.getElementById(id)
  var width = video.width
  var height = video.height

  // create canvas element
  var imgPlaceholder = document.createElement('canvas')
  imgPlaceholder.setAttribute('width', width)
  imgPlaceholder.setAttribute('height', height)
  document.body.appendChild(imgPlaceholder)
  var context = imgPlaceholder.getContext('2d')
  context.drawImage(video, 0, 0, width, height)

  // download canvas image
  imgPlaceholder.toBlob(function (blob) {
    var link = document.createElement('a')
    var url = (window.URL ? window.URL : window.webkitURL).createObjectURL(blob)
    link.href = url
    link.setAttribute('download', fileName)
    link.setAttribute('target', '_blank')
    link.click()
    link.parentNode && link.parentNode.removeChild(link)
    imgPlaceholder.parentNode && imgPlaceholder.parentNode.removeChild(imgPlaceholder)
  })
}

export const utils = {
  Resources,
  readableBytes,
  readableKB,
  readableNumber,
  formatedNumber,
  getImageDateTime,
  getUser,
  displays_encode,
  displays_decode,
  getBrowserFullscreenElementProp,
  timeAgo,
  getSnapUrl,
  showNoImage,
  CAMERA_OFFLINE,
  isEmpty,
  toBool,
  enumToString,
  getDisplayStatusColor,
  TEMP_RANGE_MOD: TEMP_RANGE_MOD,
  TEMP_RANGE_RX: TEMP_RANGE_RX,
  TEMP_RANGE_WEATHER: TEMP_RANGE_WEATHER,
  TEMP_RANGE_DELTA: TEMP_RANGE_DELTA,
  TEMP_RANGE_PC: TEMP_RANGE_PC,
  TEMP_RANGE_RTR: TEMP_RANGE_RTR,
  BRIGHTNESS_RANGE: BRIGHTNESS_RANGE,
  TEMPERATURE_MONITOR_STATUS: TEMPERATURE_MONITOR_STATUS,
  MAX_TEMPERATURE_HISTORY_IN_YEARS: MAX_TEMPERATURE_HISTORY_IN_YEARS,
  TIMEZONE_MONITOR_STATUS: TIMEZONE_MONITOR_STATUS,
  CAMERA_MONITOR_STATUS: CAMERA_MONITOR_STATUS,
  CAMERA_MONITOR_STATUS_DEFAULT: CAMERA_MONITOR_STATUS_DEFAULT,
  ONLINE_STATUS: ONLINE_STATUS,
  SNAPSHOT_STATUS: SNAPSHOT_STATUS,

  ALERT_STATUS: ALERT_STATUS,
  ALERT_LIFECYCLE: ALERT_LIFECYCLE,
  ALERT_SEVERITY: ALERT_SEVERITY,
  ALERT_TYPE: ALERT_TYPE,
  ALERT_ITEM_TYPE: ALERT_ITEM_TYPE,
  ALERT_STATE: ALERT_STATE,
  MAX_ALERT_HISTORY_IN_DAYS,
  getEnumText,

  Round,
  RoundPrecise,
  marshallTZStat,
  marshallPingStat,
  marshallTempStat,
  marshallOfflineStat,
  addMonitorTemps,
  getMonitorMap,
  htmlDecode,
  textMultiline,

  PC_INTERVAL_IN_MIN: PC_INTERVAL_IN_MIN,
  DATA_INTERVAL_IN_MIN: DATA_INTERVAL_IN_MIN,
  ROUTER_INTERVAL_IN_MIN: ROUTER_INTERVAL_IN_MIN,
  WEATHER_INTERVAL_IN_MIN: WEATHER_INTERVAL_IN_MIN,
  FORMAT_DATE_TIME: FORMAT_DATE_TIME,
  allowTemperature,
  getPointsRange,
  getLabelSets,
  extrapolateToLabelPoints,

  unixUtcToTzStr,
  fetchTimeout,
  getProps,
  parseQueryString,
  onCreateTicket,
  takeVideoSnapshot
};