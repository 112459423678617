import { alertConstants } from '../_constants';
import { alertService } from '../_services';

export const alertActions = {
  success,
  error,
  clear,
  getAlerts
};

function success(message) {
  return { type: alertConstants.SUCCESS, message };
}

function error(message) {
  return { type: alertConstants.ERROR, message };
}

function clear() {
  return { type: alertConstants.CLEAR };
}

function getAlerts() {
  return dispatch => {
    dispatch(request());

    alertService.getAlerts()
      .then(
        alerts => dispatch(success(alerts)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: alertConstants.GET_ALERTS_ALL_REQUEST } }
  function success(alerts) { return { type: alertConstants.GET_ALERTS_ALL_SUCCESS, alerts } }
  function failure(error) { return { type: alertConstants.GET_ALERTS_ALL_FAILURE, error } }
}
