import { authHeader } from '../_helpers';
import { authService } from './';
// import axios from 'axios';
import { config } from '../_config';
const apiUrl = config.apiUrl
//const apiUrl = "http://localhost:3001";  // <---- ONLY for testing

export const alertService = {
  getAlerts,
}

function getAlerts() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const qs = "" // "deleted=true&topn=10&severity=Critical"

  return fetch(`${apiUrl}/alerts?${qs}`, requestOptions)
        .then(authService.handleResponse)
}