import { reqOptions } from '../_helpers';
import axios from 'axios';
import { config } from '../_config';
const apiUrl = config.apiUrl
//const apiUrl = "http://localhost:3006";  // <---- ONLY for testing

export const temperatureService = {
  getHistory,
  getHistoryRange,
};

const ACTIONS = {
  "HISTORY": Symbol("temp-history"),
}

function getHistory(id, date) {
  let requestOptions = reqOptions(ACTIONS.HISTORY)
  return axios.get(`${apiUrl}/temperature/${id}/history?date=${date.format("YYYY-MM-DD")}`, requestOptions).then(handleResponse);
}

function getHistoryRange(id, min, max) {
  let requestOptions = reqOptions(ACTIONS.HISTORY)
  return axios.get(`${apiUrl}/temperature/${id}/history?startDate=${min.format("YYYY-MM-DD HH:mm:ss")}&endDate=${max.format("YYYY-MM-DD HH:mm:ss")}`, requestOptions).then(handleResponse);
}

// Helpers functions ---------------------
function handleResponse(response) {
  return response.data;
}
