import { authHeader } from '../_helpers';
import { authService } from './';
import { config } from '../_config';
const apiUrl = config.apiUrl
//const apiUrl = "http://localhost:3001";  // <---- ONLY for testing

export const maintenanceService = {
  getMaintenanceMessages,
}

function getMaintenanceMessages() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${apiUrl}/maintenance/`, requestOptions)
        .then(authService.handleResponse)
}