import React, {Component, useEffect, useState} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { connect } from 'react-redux';
import filterFactory, { textFilter, selectFilter, Comparator } from 'react-bootstrap-table2-filter';
import toggleClasses from "@coreui/react/es/Shared/toggle-classes";
import {asideMenuCssClasses} from "@coreui/react/es/Shared/index";
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { FaExclamationTriangle, FaTimesCircle } from 'react-icons/fa';
import { WiDayCloudy } from "react-icons/wi";
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Progress } from 'reactstrap';
import _ from 'lodash';
import 'moment-timezone';
import moment from 'moment';
import Slider from '@material-ui/core/Slider';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {displayActions} from "../../../api/_actions";
import { utils } from '../../../utils'
  
export const RangeSlider = ({onFilter, column, min, max, step, plus, suffix, clear}) => {
  min = min || 0
  max = max || 4
  step = step || 1
  plus = plus || false
  suffix = suffix || ""
  clear = clear || false
  let defaultValue = [min, max]

  const [value, setValue] = useState(defaultValue)

  const handleChange = (event, newValue) => {
    if (clear) return
    setValue(newValue);
    onFilter([column.dataField, min, max, ...newValue])
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [ clear ])

  return (
    <Slider
      key={`slider-${column.text}`}
      value={value}
      min={min}
      max={max}
      step={step}
      onChange={handleChange}
      valueLabelDisplay="auto"
      valueLabelFormat={val => val + (plus && (val === max) ? "+" : "") + suffix}
      className="d-block mt-1"
    />
  )
}

class DisplayTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      autoSelectFirstRow: true
    }

    TimeAgo.locale(en);
    this.deselectDisplay = this.deselectDisplay.bind(this)
    this.selectDisplay = this.selectDisplay.bind(this)
    this.headerFormatterX = this.headerFormatterX.bind(this)
    const  displayId = _.get(props, "match.params.DisplayId")
    const user = utils.getUser()
    const viewExt = user.permissions.canViewExt(utils.Resources.displayStatus)
    this.adminRole = (_.get(user,"payload.role") === "admin")

    const selectOptions = {
      'Online': 'Online',
      'Offline': 'Offline',
      'not-monitored': 'not-monitored',
      'not-Installed': 'not-Installed',
    };

    this.columns = [
      {
        hidden: !viewExt,
        dataField: 'Error',
        text: ' Error',
        headerTitle: (column, colIndex) => `  Configuration Errors`,
        headerStyle: { width: '58px'},
        headerFormatter: headerFormatterError,
        headerAttrs: { tabIndex: "-1"},
        formatter: errorFormatter,
        sort: true,
        sortFunc: errorSort,
      }, {
        hidden: !viewExt,
        dataField: 'DisplayId',
        text: ' ID',
        headerTitle: (column, colIndex) => `  Display ID`,
        headerStyle: { width: '81px'},
        headerFormatter: this.headerFormatterX,
        headerAttrs: { tabIndex: "-1"},
        sort: true,
        sortFunc: errorSort,
        filter: textFilter({
          comparator: Comparator.EQ,
          defaultValue: displayId,
          onFilter: this.filterByDisplayId,
          getFilter: filter => this.setState({idFilter: filter})
        }),
      }, {
        dataField: 'CompanyName',
        text: ' Company',
        filter: textFilter(),
        headerFormatter: headerFormatter,
        headerAttrs: { tabIndex: "-1"},
        sort: true
      }, {
        dataField: 'ProjectName',
        text: 'Project',
        filter: textFilter(),
        headerFormatter: headerFormatter,
        headerAttrs: { tabIndex: "-1"},
        sort: true
      }, {
        dataField: 'SerialNumber',
        text: ' S/N',
        headerTitle: (column, colIndex) => `Serial Number`,
        filter: textFilter(),
        headerFormatter: headerFormatter,
        headerAttrs: { tabIndex: "-1"},
        sort: true
      }, {
        dataField: 'RemoteAccess.Ip',
        text: ' WAN IP',
        filter: textFilter(),
        headerFormatter: headerFormatter,
        headerAttrs: { tabIndex: "-1"},
        sort: true
      }, {
        hidden: !viewExt,
        dataField: 'Status.SemanticStatus.State',
        text: ' Status',
        sort: true,
        headerFormatter: headerFormatter,
        headerAttrs: { tabIndex: "-1"},
        formatter: stateFormatter,
        filter: selectFilter({
          options: selectOptions,
          comparator: Comparator.LIKE
        })
      }, {
        hidden: !viewExt,
        dataField: 'Status.OperatingPercentage.Visual',
        text: ' V.O.P.',
        headerTitle: (column, colIndex) => `Visual Operating Percentage`,
        headerStyle: { width: '5rem'},
        sort: true,
        sortFunc: percentSort,
        formatter: percentFormatter,
        headerFormatter: headerFormatter,
        headerAttrs: { tabIndex: "-1"},
        filter: textFilter({onFilter: this.filterByRange}),				
        filterRenderer: (onFilter, column) => <RangeSlider onFilter={onFilter} column={column} min={0} max={100} step={1} suffix="%"/>
      }, {
        hidden: !viewExt,
        dataField: 'Status.Brightness',
        text: ' Brightness',
        sort: true,
        sortFunc: brightnessSort,
        formatter: brightnessFormatter,
        headerFormatter: headerFormatter,
        headerAttrs: { tabIndex: "-1"},
        filter: textFilter({onFilter: this.filterByRange}),
        filterRenderer: (onFilter, column) => {
          let {brightnessMissmatchFilterValue} = this.state
          return (<div className="d-flex">
                  <RangeSlider onFilter={onFilter} column={column} min={0} max={100} step={1} suffix="%" clear={brightnessMissmatchFilterValue}/>
                  <span className={`brightness-filter btn btn-sm btn-pill ${brightnessMissmatchFilterValue ? "btn-light" : "btn-dark text-muted"}`} 
                    onClick={() => {
                      brightnessMissmatchFilterValue = !brightnessMissmatchFilterValue
                      this.setState(
                        {brightnessMissmatchFilterValue}, 
                        () => onFilter(brightnessMissmatchFilterValue)
                      )
                    }}
                    title="Toggle brightness missmatch filter to show only displays with port brightness missmatch">
                      &ne;
                  </span>
          </div>
          )}
      }, {
        hidden: !viewExt,
        dataField: 'Status.Temperature.Average',
        text: ' M. Temp.',
        headerTitle: (column, colIndex) => `Module Average Temperature`,
        sort: true,
        sortFunc: percentSort,
        formatter: tempFormatter,
        headerFormatter: headerFormatter,
        headerAttrs: { tabIndex: "-1"},
        headerStyle: { width: '7em'},
        filter: textFilter({onFilter: this.filterByRange}),				
        filterRenderer: (onFilter, column) => <RangeSlider onFilter={onFilter} column={column} min={-10} max={80} step={1} plus={true} suffix="C&deg;"/>
      }, {
        hidden: !viewExt,
        dataField: 'Status.TemperatureRx.Average',
        text: ' R. Temp.',
        headerTitle: (column, colIndex) => `Receiver Average Temperature and \nWeather Temperature`,
        sort: true,
        sortFunc: percentSort,
        formatter: tempFormatterRx,
        headerFormatter: headerFormatter,
        headerAttrs: { tabIndex: "-1"},
        headerStyle: { width: '7em'},
        filter: textFilter({onFilter: this.filterByRange}),				
        filterRenderer: (onFilter, column) => <RangeSlider onFilter={onFilter} column={column} min={-10} max={80} step={1} plus={true} suffix="C&deg;"/>
      }, {
        hidden: !viewExt,
        dataField: 'Status.Lastbeat',
        text: ' Last beat',
        headerTitle: (column, colIndex) => `Last checked in time by Synapse in minutes`,
        sort: true,
        formatter: dateFormatter,
        headerFormatter: headerFormatter,
        headerAttrs: { tabIndex: "-1"},
        headerStyle: { width: '8em'},
        filter: textFilter({onFilter: this.filterByBeat}),				
        filterRenderer: (onFilter, column) => <RangeSlider onFilter={onFilter} column={column} min={0} max={90} step={1} plus={true}/>
      }, {
        hidden: !viewExt,
        dataField: 'Status.AlertCount',
        text: ' Alerts',
        sort: true,
        sortFunc: alertSort,
        formatter: alertFormatter,
        headerFormatter: headerFormatter,
        headerAttrs: { tabIndex: "-1"},
        headerStyle: { width: '6em'},
        filter: textFilter({onFilter: this.filterByAlertCount}),				
        filterRenderer: (onFilter, column) => <RangeSlider onFilter={ onFilter } column={ column } plus={true} />
      }];
  }
  
  componentDidMount() {
    this.interval = setInterval(() => this.props.dispatch(displayActions.getSummary()), 30000);
    if (!this.props.displays || this.props.displays.length <= 0) {
      this.props.dispatch(displayActions.getSummary());
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.interval = null
    this.deselectDisplay();
  }

  deselectDisplay() {
    const { selectedDisplay } = this.props;
    const currentlySelected = selectedDisplay;

    // deselect
    this.props.dispatch(displayActions.deselectDisplay());
    
    //only toggle if something is selected
    if (currentlySelected) {
      if (this.props.mobile) {
        document.body.classList.toggle('aside-menu-show');
      } else {
        var cssTemplate = 'aside-menu-lg-show';
        var _asideMenuCssClasses$ = asideMenuCssClasses[0],
          cssClass = _asideMenuCssClasses$[0];

        if (asideMenuCssClasses.indexOf(cssTemplate) > -1) {
          cssClass = cssTemplate;
        }
        toggleClasses(cssClass, asideMenuCssClasses);
      }
    }
  }
  selectDisplay(DisplayId) {

    const { selectedDisplay } = this.props;
    const currentlySelected = selectedDisplay;
    let selectAction = -1;
    if (selectedDisplay === DisplayId) {
      this.props.dispatch(displayActions.deselectDisplay());
      selectAction = 0;
      this.changeUrl(0)
    } else {
      this.props.dispatch(displayActions.selectDisplay(DisplayId));
      selectAction = 1;
      this.changeUrl(DisplayId)
    }

    //only toggle if we are selecting or deselecting, not changing
    if ((currentlySelected && selectAction === 0) || (!currentlySelected && selectAction === 1)) {
      if (this.props.mobile) {
        document.body.classList.toggle('aside-menu-show');
      } else {
        var cssTemplate = 'aside-menu-lg-show';
        var _asideMenuCssClasses$ = asideMenuCssClasses[0],
          cssClass = _asideMenuCssClasses$[0];

        if (asideMenuCssClasses.indexOf(cssTemplate) > -1) {
          cssClass = cssTemplate;
        }
        toggleClasses(cssClass, asideMenuCssClasses);
      }
    }

  }

  filterByDisplayId = (filterVal, data) => {
    const { selectedDisplay } = this.props
    const { autoSelectFirstRow } = this.state
    let r = data

    if (filterVal) {
      r = data.filter(display => display.DisplayId === Number(filterVal));
    }
    if (autoSelectFirstRow && !selectedDisplay && r && r.length) {
      let displayId = r[0].DisplayId
      this.setState({autoSelectFirstRow: false}, () => {
        if (displayId) {
          this.selectDisplay(displayId)
        }
      })
    }
    return r;
  }

  filterByRange = (filterVal, data, reducer, field) => {
    let r = data
    if (Array.isArray(filterVal) && filterVal.length >= 2) {
      const [dataField, min = 0, max = 100, lo, hi] = filterVal
      if (lo > 0 || hi < max) {
        r = data.filter(display => {
          const vals = _.get(display, field || dataField)
          const val = reducer 
                      ? reducer(vals)
                      : Array.isArray(vals) 
                        ? vals.reduce((a,b) => a + b, min) / vals.length
                        : vals || min;
          return (hi === max) ? val >= lo : 
                 (lo === min) ? val <= hi : val >= lo && val <= hi
        });	
      }
    } else if (filterVal) {
      r = data.filter(display => {
        let hasMissmatch = false
        let bArr = _.get(display, "Status.Brightness") || []
        if (bArr.length > 1) {
          hasMissmatch = bArr.some((val) => val !== bArr[0])
        }
        return hasMissmatch
      })
    }
    
    return r;
  }

  filterByBeat = (filterVal, data) => {
    return this.filterByRange(filterVal, data, lastBeat => {
      lastBeat = (!lastBeat) ? lastBeat : moment.tz(lastBeat, "YYYY-MM-DD hh:mm:ss", "UTC").tz(moment.tz.guess());			
      const ago = utils.timeAgo(lastBeat, 365*24*60, "minutes") // 8760  - 1 year, max minutes since last heartbeat
      return ago.minutes
    })
  }

  filterByAlertCount = (filterVal, data) => {
    return this.filterByRange(filterVal, data, null, "Status.AlertCount.Total")
  }

  changeUrl = (displayId) => {
    let url = '/status' + ((displayId) ? '/' + displayId : "");
    window.history.replaceState('Status', 'Title', url); // change without adding it to the history
  }

  headerFormatterX(column, colIndex, { sortElement, filterElement }) {
    return (
      <React.Fragment>
      <div title={column.title}>
        { column.text } { sortElement }
        { filterElement }
        <FaTimesCircle  size={16} color="muted" className="btn-clear" onClick={this.clearIdFilter} title="Clear ID filter"/>
      </div>
    </React.Fragment>
    );
  }

  clearIdFilter = () => {
    let filter = this.state.idFilter
    filter && filter("")
  }

  render() {

    const defaultSorted = [{
      dataField: 'Status.OperatingPercentage.Visual',
      order: 'desc'
    }];

    const rowClasses = (row, rowIndex) => {
      let classes = null;
      const { selectedDisplay } = this.props;
      if (row.DisplayId === selectedDisplay) {
        classes = 'table-row-select';
      }

      return classes;
    };

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.selectDisplay(row.DisplayId)
      }
    };
    
    const { displays, loading } = this.props;

    // custom drop down for page size
    const {dropdownOpen} = this.state;
    const toggle = () => this.setState({dropdownOpen: !dropdownOpen});    
    const sizePerPageRenderer = ({
      options,
      currSizePerPage,
      onSizePerPageChange
    }) => (
      <React.Fragment>
        <div className="text-nowrap">
          <ButtonDropdown direction="up" isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret>{currSizePerPage}</DropdownToggle>
            <DropdownMenu>
              {
                options.map(option => (
                  <DropdownItem key={option.page} onClick={ () => onSizePerPageChange(option.page) }>{option.text}</DropdownItem>
                ))
              }
            </DropdownMenu>
          </ButtonDropdown>
          {this.adminRole && <ReactHTMLTableToExcel  
            className="btn btn-dark ml-2"
            table="tblDisplays"  
            filename="Displays"  
            sheet="Sheet"  
            buttonText="Export excel" />  
          }
        </div>
      </React.Fragment>
    );

    const options = {
      sizePerPageRenderer,
      sizePerPageList: [ 
        {text: '10', value: 10}, 
        {text: '25', value: 25}, 
        {text: '30', value: 30}, 
        {text: '50', value: 50}, 
        {text: 'All', value: displays && displays.length}
      ]
    };
    // /custom drop down for page size

    return (
      <div className="animated">
        {(!displays && loading) && <div>Loading...</div>}
        {displays &&
          <BootstrapTable
            id="tblDisplays"
            version='4'
            keyField='DisplayId'
            data={displays}
            columns={this.columns}
            pagination={ paginationFactory(options) }            
            filter={ filterFactory() }
            rowEvents={ rowEvents }
            rowClasses={ rowClasses }
            defaultSorted={ defaultSorted }
            hover
          >
          </BootstrapTable>
        }
      </div>
    );
  }
}

function mapSeverityToClass(severity) {
  switch(severity) {
    case("Critical"):
      return "badge-danger";
    case("Error"):
      return "badge-danger";
    case("Failover"):
      return "badge-warning";
    case("Warning"):
      return "badge-info";
    default:
      return "badge-undef op-20";
  }
}
function getMaxSeverity(AlertStatus) {
  if (AlertStatus.Critical > 0) {
    return "Critical";
  }
  else if (AlertStatus.Error > 0) {
    return "Error";
  }
  else if (AlertStatus.Failover > 0) {
    return "Failover";
  }
  else if (AlertStatus.Warning > 0) {
    return "Warning";
  }
  else {
    return "nil";
  }
}
function severityColor(minutesAgo, state) {
  switch(state) {
    case "not-monitored": return "primary";
    case "not-installed": return "secondary";
    default:
      if (minutesAgo <= 12) {
        return "success"
      } else {
        return "danger"
      }
  }
}

// -- Sorters ---------------------
function percentSort(a, b, order, dataField) {
  let aNA = (a === "nil" || a === "");
  let bNA = (b === "nil" || b === "");

  if (order === 'asc') {
    //return if A is greater... unless its NA, then make sure it's not.
    if (aNA && bNA) {
      return 0;
    } else if (bNA) {
      return -1;
    } else if (aNA) {
      return 1;
    } else {
      return (parseFloat(a) - parseFloat(b));
    }
  }
  else {
    if (aNA && bNA) {
      return 0;
    } else if (bNA) {
      return -1;
    } else if (aNA) {
      return 1;
    } else {
      //return if B is greater... unless it's not
      return (parseFloat(b) - parseFloat(a)); // desc
    }
  }
}
function alertSort(a, b, order, dataField) {
  if (order === 'asc') {
    //handle non-existence of objects
    if (typeof(a) !== "object") {
      return -1
    }
    else if (typeof(b) !== "object") {
      return 1
    }
    //compare severities, going down if necessary
    if (a.Critical !== b.Critical) {
      return parseInt(a.Critical, 10) - parseInt(b.Critical, 10)
    }
    else if (a.Error !== b.Error) {
      return parseInt(a.Error, 10) - parseInt(b.Error, 10)
    }
    else if (a.Failover !== b.Failover) {
      return parseInt(a.Failover, 10) - parseInt(b.Failover, 10)
    }
    else if (a.Warning !== b.Warning) {
      return parseInt(a.Warning, 10) - parseInt(b.Warning, 10)
    }
    else if (a.Total !== b.Total) {
      return parseInt(a.Total, 10) - parseInt(b.Total, 10)
    }
    else {
      return 0
    }

  }
  else {
    //handle non-existence of objects
    if (typeof(a) !== "object") {
      return 1
    }
    else if (typeof(b) !== "object") {
      return -1
    }
    //compare severities, going down if necessary
    if (a.Critical !== b.Critical) {
      return parseInt(b.Critical, 10) - parseInt(a.Critical, 10)
    }
    else if (a.Error !== b.Error) {
      return parseInt(b.Error, 10) - parseInt(a.Error, 10)
    }
    else if (a.Failover !== b.Failover) {
      return parseInt(b.Failover, 10) - parseInt(a.Failover, 10)
    }
    else if (a.Warning !== b.Warning) {
      return parseInt(b.Warning, 10) - parseInt(a.Warning, 10)
    }
    else if (a.Total !== b.Total) {
      return parseInt(b.Total, 10) - parseInt(a.Total, 10)
    }
    else {
      return 0
    }
  }
}
function brightnessSort(a, b, order, dataField) {

  let highest_a = -1;
  let highest_b = -1;

  let lowest_a = -1;
  let lowest_b = -1;

  if (a) {
    highest_a = Math.max(...a);
    lowest_a = Math.min(...a);
  }

  if (b) {
    highest_b = Math.max(...b);
    lowest_b = Math.min(...b);
  }

  if (order === 'asc') {
    return lowest_a -lowest_b;
  } else {
    return highest_b - highest_a;
  }
}
function errorSort(a, b, order, dataField) {
  //modified so nil is grouped together
  if (order === 'asc') {
    //compare severities, going down if necessary
    if (a === "nil" && b !== "nil") {
      return 1
    }
    else if (a !== "nil" && b === "nil") {
      return -1
    }
    else {
      return a - b
    }
  }
  else {
    //compare severities, going down if necessary
    if (a === "nil" && b !== "nil") {
      return -1
    }
    else if (a !== "nil" && b === "nil") {
      return 1
    }
    else {
      return b - a
    }
  }
}
// -- /Sorters ---------------------

// -- Formaters ---------------------
function tempFormatter(cell, row) {
  if (["","nil"].includes(_.get(row, "Status.Temperature.Average", ""))) {
    return (<div>N/A</div>);
  } 
  return (<div>{cell} &deg;C</div>);
}
function tempFormatterRx(cell, row) {
  const highTemp = 60
  const whTemp = _.get(row, "Weather.Temp")
  const highTemps = cell >= highTemp || whTemp >= highTemp

  const rxTemp = (cell && cell !== "nil") ? (<React.Fragment>{cell}&deg;C</React.Fragment>) :  "N/A"
  const rxTempBadge = (cell >= highTemp)
                    ? (<span style={{minWidth: 20 + 'px'}} className="align-self-center badge badge-danger" title="Receiver Average Temperature">
                        {rxTemp} 
                      </span>)
                    : (<span className={highTemps ? "ml-1" :""}>{rxTemp}</span>);

  const weatherTemp = (whTemp && whTemp !== "nil") ? (<React.Fragment>{whTemp} &deg;C</React.Fragment>) : "N/A"
  const whTempBadge = <span style={{minWidth: 20 +'px'}} className={`align-self-${whTemp >= highTemp ? "center ml-1" : "end"} badge badge-${whTemp >= highTemp ? "danger" : "transparent"}`} title="Weather Temperature">
                        {whTemp < highTemp && <WiDayCloudy size={12} className="op-80"/>}
                        {weatherTemp}
                      </span>;

    return (
      <div className="d-inline-flex ml-minus-2">
        {rxTempBadge}
        {whTempBadge}
      </div>
    );
}
function stateFormatter(cell, row) {

  const timeAgo = new TimeAgo('en-US');
    if (row && row.Status && row.Status.SemanticStatus && row.Status.SemanticStatus.State === "online") {
      return (
        <span className="state-badge badge badge-success" data-toggle="tooltip" data-placement="top" title={timeAgo.format(Date.parse(row.Status.Lastbeat + " UTC"))}>Online</span>
      );
    }
    else if (row && row.Status && row.Status.SemanticStatus && row.Status.SemanticStatus.State === "offline") {
      return (
        <span className="state-badge badge badge-danger" data-toggle="tooltip" data-placement="top" title={timeAgo.format(Date.parse(row.Status.Lastbeat + " UTC"))}>Offline</span>
      );
    }
    else if (row && row.Status && row.Status.SemanticStatus && row.Status.SemanticStatus.State === "not-installed") {
      return (
        <span className="state-badge badge badge-secondary" data-toggle="tooltip" data-placement="top" title={timeAgo.format(Date.parse(row.Status.Lastbeat + " UTC"))}>Not-Installed</span>
      );
    }
    else if (row && row.Status && row.Status.SemanticStatus && row.Status.SemanticStatus.State === "not-monitored") {
      return (
        <span className="state-badge badge badge-primary" data-toggle="tooltip" data-placement="top" title={timeAgo.format(Date.parse(row.Status.Lastbeat + " UTC"))}>Not-Monitored</span>
      );
    }
    else {
      return (
        <span className="state-badge badge badge-undef">N/A</span>
      );
    }
}
function alertFormatter(cell, row) {

  if (row && row.Status && row.Status.AlertCount) {
    return (
      <div>
      <span style={{minWidth: 30 +'px'}} className={"badge p-1 mx-1 " + mapSeverityToClass(getMaxSeverity(row.Status.AlertCount))}>{row.Status.AlertCount.Total}</span>
      </div>
    );
  }
  else {
    return (
      <span style={{minWidth: 30 +'px'}} className="badge p-1 mx-1 badge-undef op-20">0</span>
    );
  }
}
function brightnessFormatter(cell, row) {

  let lowest = 100;
  if (row.Status && row.Status.Brightness) lowest = Math.min(...row.Status.Brightness);

  return (
    //calculate the lowest value. Anything above that will be flagged
    <span>
      {row.Status && row.Status.Brightness && row.Status.Brightness.map((val, index) =>
        <span key={index} style={{minWidth: 30 +'px'}} className={"badge p-1 mx-1 badge-" + (val === lowest ? "success" : "warning")}>
          {val}
        </span>
      )}
    </span>
  );
}
function errorFormatter(cell, row) {
  let msg = ""
  switch(row && row.Error) {
    case "nil": break;      // skip it
    case "AbsentModules": msg = row.Error + " - Reported modules does not match the project configuration. Project may needs to be re-created."; break;
    case "PixelPitchMismatch": msg = row.Error + " - Reported pixel pitch does not match the project configuration. Project may needs to be re-created."; break;
    default: msg = row.Error; break;
  }
  return (msg &&
        <span data-toggle="tooltip" data-placement="top" title={msg}><FaExclamationTriangle  size={16} color="yellow"/></span>
  );
}
function percentFormatter(cell, row) {
  let value = 100;
  let text = "N/A";
  let undef = true;
  let hoverText = "";
  let showPercentage = true;
  let visual = _.get(row, "Status.OperatingPercentage.Visual")

  if (visual && visual !== "" && visual !== "nil") {
    //visual calculation
    if (row.Status.ComponentStatus && row.Status.ComponentStatus.Module) {
      let visCount = row.Status.ComponentStatus.Module.Online + row.Status.ComponentStatus.Module.Failover + row.Status.ComponentStatus.Module.Unknown;
      hoverText = visCount + "/" + row.Status.ComponentStatus.Module.Total;
      showPercentage = (visCount > 0) || (row.Status.ComponentStatus.Module.Total > 0);
    }
    if (showPercentage) {
      value = visual;
      undef = false
      text = value + " %";
    } else {
      value = 0;
      hoverText += " (" + visual + ")"
    }
  }
  else if (row.Status && row.Status.ComponentStatus && row.Status.ComponentStatus.Module) {
    hoverText = "Absent: " + row.Status.ComponentStatus.Module.Absent;
  }

  return (
      <div className="progress">
        <div className={"progress-bar bg-" + ((undef) ? "gray-600" : "primary")} role="progressbar" style={{width: value + '%'}}
             aria-valuenow={value} aria-valuemin="0" aria-valuemax="100"
             data-toggle="tooltip" data-placement="top" title={hoverText}>{ text }</div>
      </div>
  );
}
function dateFormatter(cell, row) {
  let lastBeat = _.get(row, "Status.Lastbeat")
  lastBeat = (!lastBeat) ? lastBeat : moment.tz(lastBeat, "YYYY-MM-DD hh:mm:ss", "UTC").tz(moment.tz.guess());

  const ago = utils.timeAgo(lastBeat, 365*24*60, "minutes") // 8760  - 1 year, max minutes since last heartbeat

  if (ago.minutes<=12) {
    ago.percent = 100
    ago.description = "now"
  } else {
    if (ago.percent>0 && ago.percent<100) {
      ago.percent = 100 - ago.percent
    }
  }

  return (
    <Progress color={severityColor(ago.minutes, _.get(row, "Status.SemanticStatus.state"))} 
              value={ago.percent} 
              title={`Last beat on ${ago.title} (${ago.description})`}
              className="bg-gray-600" barClassName="pl-2OFF">{ago.description}</Progress>
  )
}
function headerFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div title={column.title} className="text-nowrap">
      { column.text } { sortElement }
      <div onClick={(e)=> {e.stopPropagation(); return false;}}>
        { filterElement }
      </div>
    </div>
  );
}
function headerFormatterError(column, colIndex, { sortElement, filterElement }) {
  return (
    <div title={column.title} className="text-nowrap">
      <FaExclamationTriangle  size={16} color="yellow"/>
      { sortElement }
      { filterElement }
    </div>
  );
}
// -- /Formaters ---------------------


function mapStateToProps(state) {
  const { selectedDisplay, displays, display, detail, alerts, loading } = state.display;
  const { user } = state.authentication;

  return {
    selectedDisplay, displays, display, detail, alerts, user, loading
  };

}

const connectedDisplayTable = connect(mapStateToProps)(DisplayTable);
export default connectedDisplayTable;
