import React, { Component, useState } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { Form, Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row } from 'reactstrap';
import { authActions } from '../../api/_actions';
import { SpinnerDots } from '../../_components/SpinnerDots'

const LoginMfa = (props) => {
  const {mfaToken, fromUrl, loggingIn, error} = props
  const [mfa_code, setMfaCode] = useState('');
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setMfaCode(e.target.value)
  }
  const handleKeyPress = (e) => {
    if(e.key === 'Enter'){
      handleSubmit(e)
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (mfaToken) {
      if (mfa_code) {
        dispatch(authActions.loginMfa(mfaToken, mfa_code, fromUrl));
      }
    } 
  }

  return <React.Fragment>
    {!!mfaToken && 
      <CardBody>
        <h1>Multi-factor Authentication</h1>
        <p className="text-muted">Enter an MFA code to complete sign-in.</p>
        <Label for="mfaCode">MFA Code</Label>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="icon-lock"></i>
            </InputGroupText>
          </InputGroupAddon>
          <Input type="text" id="mfa_code" name="mfa_code"  value={mfa_code} placeholder="e.g. 123123" onKeyPress={handleKeyPress} onChange={handleChange} autoFocus/>
        </InputGroup>
        {!!error && 
          <Row className={"justify-content-center"}>
            <p className="text-error m-0">Invalid MFA code.</p>
          </Row>
        }
        <Row className={"mt-4"}>
          <Col xs="6">
            <Button className="px-4" onClick={handleSubmit}>Submit</Button>
            {loggingIn &&
            <img alt="logging in" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            }
          </Col>
          <Col xs="6" className="text-right">
            <Link className="px-1 text-light align-middle" to="/logout" >Cancel</Link>
          </Col>
        </Row>
      </CardBody>}
    </React.Fragment>
}
class Login extends Component {
  constructor(props) {
    super(props);

    // reset login status
    this.props.dispatch(authActions.logout(false));

    this.state = {
      username: '',
      password: '',
      mfa_code: '',
      email: "",
      forgotPwd: false,
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.toggleForgotPassword = this.toggleForgotPassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }


  handleKeyPress(e) {
    if(e.key === 'Enter'){
      this.handleSubmit(e)
    }
  }

  toggleForgotPassword(){    
    this.setState({forgotPwd: !this.state.forgotPwd, error: null})
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username, password, mfa_code, forgotPwd, email } = this.state;
    const fromUrl = _.get(this.props,"location.state.from.pathname")
    const mfaToken = _.get(this.props, "user.mfaToken")
    
    if (forgotPwd) {
      if (email) {
        this.props.dispatch(authActions.forgotPassword(email, fromUrl));
      } else {
        this.emailRef && this.emailRef.focus()
      }
    } else if (mfaToken) {
      if (mfa_code) {
        this.props.dispatch(authActions.loginMfa(mfaToken, mfa_code, fromUrl));
      }
    } else {
      if (username && password) {
        this.props.dispatch(authActions.login(username, password, fromUrl));
      }
    }
  }

  render() {
    const { loggingIn, error, errorForgotPwd, user, callingForgotPwd, calledForgotPwd } = this.props;
    const { username, password, forgotPwd, email, submitted } = this.state;
    const fromUrl = _.get(this.props,"location.state.from.pathname")
    const mfaToken = _.get(user, "mfaToken")

    const formLogin = <CardBody>
                        <h1>Login</h1>
                        <p className="text-muted">Sign In to your account</p>
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-user"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input type="text" name="username" value={username} placeholder="Username" onKeyPress={this.handleKeyPress} onChange={this.handleChange} autoFocus />
                          {submitted && !username &&
                          <div className="help-block">Username is required</div>
                          }
                        </InputGroup>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText><i className="icon-lock"></i></InputGroupText>
                          </InputGroupAddon>
                          <Input type="password" name="password" value={password} placeholder="Password" onKeyPress={this.handleKeyPress} onChange={this.handleChange} />
                          {submitted && !password &&
                          <div className="help-block">Password is required</div>
                          }
                        </InputGroup>
                        <Row>
                          <Col className="text-right">
                            <Button color="link" className="text-light px-0" onClick={this.toggleForgotPassword}>Forgot password?</Button>
                          </Col>
                        </Row>
                        {!!error && 
                        <p className="text-error mb-1 text-center">Invalid username or password.</p>
                        }
                        <Row>
                          <Col className="mt-4">
                            <Button className="btn-block" onClick={this.handleSubmit}>
                              Login
                              <SpinnerDots loading={loggingIn} className="ml-3" />
                            </Button>
                          </Col>
                        </Row>
                      </CardBody>

    const formForgotPwd = <CardBody>
                        <h1 className="text-nowrap">Forgot password</h1>
                        <p className="text-muted">Enter the email addres and the password reset link will be sent to you.</p>
                        <Form>
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>@</InputGroupText>
                            </InputGroupAddon>
                            <Input type="email" name="email" value={email} placeholder="Email" onKeyPress={this.handleKeyPress} onChange={this.handleChange} autoFocus ref={(input) => { this.emailRef = input }}/>
                          </InputGroup>
                          {!!errorForgotPwd && 
                          <p className="text-error mb-1 text-center">Error: {"" + errorForgotPwd}</p>
                          }
                          {!!calledForgotPwd && 
                          <p className="mb-1 text-center text-warning">Reset password link is sent!</p>
                          }
                          <Row className={"mt-4"}>
                            <Col xs="6" className="text-left">
                              <Button color="" className="px-0 text-light" onClick={this.toggleForgotPassword}>Cancel</Button>
                            </Col>
                            <Col xs="6" className="text-right">
                              <Button className="px-4" onClick={this.handleSubmit}>
                                Submit
                                <SpinnerDots loading={callingForgotPwd} className="ml-3" />                                
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </CardBody>

    const _form  = () => {
      switch (true){
        case !!forgotPwd: return formForgotPwd;
        case !!mfaToken: return <LoginMfa mfaToken={mfaToken} fromUrl={fromUrl} loggingIn={loggingIn} error={error} />;
        default: return formLogin;
      }
    }
    
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6" >
              <CardGroup>
                <Card className="text-white bg-primary p-4">
                  {_form()}
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}


function mapStateToProps(state) {
  const { loggingIn, error, user, errorForgotPwd, callingForgotPwd, calledForgotPwd } = state.authentication;
  return {
    loggingIn,
    error,
    user,
    errorForgotPwd,
    calledForgotPwd,
    callingForgotPwd
  };
}

const connectedLoginPage = connect(mapStateToProps)(Login);
export default connectedLoginPage;
