import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-dates/initialize';
import _ from 'lodash';
import moment from 'moment';
import { monitorActions, displayActions, cameraActions } from "../../api/_actions";
import MonitorCard from "../../_components/monitor/MonitorCard"
import DetailsPC from "../../_components/monitor/detailsPC"
import { DetailsTempShort } from "../../_components/monitor/detailsTempShort"
import DetailsPingShort from "../../_components/monitor/detailsPingShort"
import DetailsWebCam from "../../_components/monitor/detailsWebCam"
import { DetailsRouter } from "../../_components/display/detailsRouter"
import { SpinnerDots } from '../../_components/SpinnerDots'
import { ChartHotDisplays, ChartDataUsage } from "../../_components/monitor/charts"
import { utils } from '../../utils';
import { ROUTER_MONITOR_STATUS } from '../../api/_constants/monitor.router.constants'
import {
  Row, Col,
} from 'reactstrap';
import {
  MdRefresh,
} from 'react-icons/md';

const DEBUG_DASHBOARD = false;
const REFRESH_TIME = 3 * 60000      // 3min
const PC_MONITOR_STATUS = {
  "FIRST_TIME_BOOTUP":   -1,  // When the app is started for the first time
  "OK":                   0,  // No issue (OK)
  "HIGH_USAGE":           1,  // Server side value - set when any vector reaches 90%
  "BOOTUP":               2,  // Bootup
  "DISABLED":             3,  // Disabled
  "PAUSED":               4,  // Paused
}
const APP_MONITOR_STATUS = {
  "OK": 0,          // No issue (OK)
  "APP_FAILURE": 1, // Some app failed
}
const APPS_GRACE_PERIOD_IN_HOUR = 24
const VIQ1_DISPLAY_IDS = [853, 1064, 1065, 1066, 1283, 1284, 1287, 1288, 1289, 1290, 1291, 1292, 1293, 1294, 1295, 1296, 1297, 1298, 1299, 1300, 1301, 1302, 1303, 1304, 1305, 1306, 1307, 1308, 1309, 1310, 1311, 1312, 1313, 1314, 1315, 1316, 1317, 1318, 1319, 1320, 1321, 1322, 1323, 1324, 1325, 1326, 1327, 1328, 1329, 1330, 1331, 1332, 1333, 1334, 1335, 1336853, 1064, 1065, 1066, 1283, 1284, 1287, 1288, 1289, 1290, 1291, 1292, 1293, 1294, 1295, 1296, 1297, 1298, 1299, 1300, 1301, 1302, 1303, 1304, 1305, 1306, 1307, 1308, 1309, 1310, 1311, 1312, 1313, 1314, 1315, 1316, 1317, 1318, 1319, 1320, 1321, 1322, 1323, 1324, 1325, 1326, 1327, 1328, 1329, 1330, 1331, 1332, 1333, 1334, 1335, 1336]

class Dashboard extends Component {
  constructor(props) {
    super(props);

    const {cm_s} = utils.parseQueryString(_.get(props, "location.search"))

    this.state = {
      expandCM: false,
      expandPC: false,
      cfg: {
        cam: {
          statuses: (cm_s ? cm_s.split(",").map(str => Number(str)) : utils.CAMERA_MONITOR_STATUS_DEFAULT)
        }
      }
    };
  }
  componentDidMount() {
    if (_.isEmpty(_.get(this.props, "data.monitor"))) {
      this.getData()
    }
    this.startAutoRefresh()
  }
  componentWillUnmount() {
    clearInterval(this.refreshInterval)
    this.refreshInterval = null
  }
  componentWillReceiveProps(nextProps) {
    const paramsChanged = (nextProps.params !== this.props.params) ||
      (nextProps.display !== this.props.display) ||
      (nextProps.monitor !== this.props.monitor);

    // after Display loading is complete, get the snapshots stats
    if (_.get(this.props, "data.display.loading") && !_.get(nextProps, "data.display.loading")) {
      setTimeout(this.getDataSnapshotStats, 0)
    }
    
    if (paramsChanged) {  
      this.setState({
        autoRefreshing: false,
      })
    } 
  }

  getDataSnapshotStats = () => {
    // get snapshots data AFTER we get the displays list
    this.props.dispatch(cameraActions.getSnapshotStats(_.get(this.props, "data.display.displays")))
              .catch(reason => console.log(reason))
  }
  getData = () => {
    const { loading } = this.props
    if (!loading) {
      this.setState({ autoRefreshing: true }, () => {
        // get display data
        if (_.isEmpty(_.get(this.props, "data.display"))) {
          if (!_.get(this.props, "data.display.loading")) {
            this.props.dispatch(displayActions.getSummary())
          }
        }
        // get monitor data
        this.props.dispatch(monitorActions.getStats())
                  .catch(reason => console.log(reason))
      })
    }
  }
  startAutoRefresh = (e) => {
    // clear existing interval
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval)
      this.refreshInterval = null
    }

    // set refresh interval
    this.refreshInterval = setInterval(() => { 
      this.getData()
      this.getDataSnapshotStats()
    }, REFRESH_TIME)
  }
  handleReload = () => {
    this.getData()
  }
  toggleExpand = (name) => {
    this.setState({ [name]: !this.state[name] });
  }

  render() {
    const { loading, error } = this.props;
    const allowTemperature = utils.allowTemperature(this.props.user)
    const dataCM = _.get(this.props, "stats.Camera")
    const dataPC = _.get(this.props, "stats.PC")
    const dataApps = _.get(this.props, "stats.Apps")
    const dataTemp = _.get(this.props, "stats.Temperatures")
    const dataTZ = _.get(this.props, "stats.TZ")
    const dataOffline = _.get(this.props, "stats.Offline")
    const dataRouter = _.get(this.props, "stats.Router")
    const dataPing = _.get(this.props, "stats.Ping")
    const dataSnapshots = _.get(this.props, "stats.Snapshots")
    const refreshButton = <div className="float-right">
                            <button className="btn btn-transparent p-0 border-0" onClick={this.handleReload} title="Reload data">
                              <MdRefresh size={20} className="align-bottom mr-1" />
                                                    Reload
                                                  </button>
                          </div>

    return (
      <div className="animated fadeIn">
        <Row className="mt-minus-3">
          <Col className="px-2 text-center">
            {!loading && refreshButton}
            <SpinnerDots loading={loading} />
            {error
              ? <span className="text-danger">{"" + error}</span>
              : loading
                ? <div>Loading...</div>
                : ""
            }
          </Col>
        </Row>

        <Row>
          <Col xs="12" sm="6" lg="3">
            <MonitorCard
                title="Offline Displays"
                toolTip="List of offline displays"
                data={dataOffline}
                statusEnum={utils.ONLINE_STATUS}
                highlightRecent="60"
              />
          </Col>
          <Col xs="12" sm="6" lg="3">
            <MonitorCard
              title="Camera Monitor"
              toolTip="Monitoring the camera detection app for failed modules"
              data={dataCM}
              dataDetails={DetailsWebCam}
              statusEnum={utils.CAMERA_MONITOR_STATUS}
              cfg={_.get(this.state, "cfg.cam", null)}
            />
          </Col>
          <Col xs="12" sm="6" lg="3">
            <MonitorCard
              title="PC Health"
              toolTip="Monitoring the PC health"
              data={dataPC}
              dataDetails={DetailsPC}
              statusEnum={PC_MONITOR_STATUS}
            />
          </Col>
          <Col xs="12" sm="6" lg="3">
            <MonitorCard
              title="Apps Failures"
              toolTip="Displays with any recent application failures (OS, Synapse,...)"
              data={dataApps}
              dataDetails={DetailsPC}
              statusEnum={APP_MONITOR_STATUS}
            />
          </Col>
          <Col xs="12" sm="6" lg="3">
            <MonitorCard
              title="Extreme Temperatures"
              toolTip="Monitoring the Receiver Temperatures vs Weather Temperature"
              data={dataTemp}
              dataShort={DetailsTempShort}
              statusEnum={utils.TEMPERATURE_MONITOR_STATUS}
              showChart={allowTemperature}
              chart={ChartHotDisplays}
              chartData={this.props.data}
              chartTitle="Displays Temperatures"
          />
          </Col>
          <Col xs="12" sm="6" lg="3">
            <MonitorCard
                title="Time Zone"
                toolTip="List of displays with invalid Time Zone"
                data={dataTZ}
                statusEnum={utils.TIMEZONE_MONITOR_STATUS}
              />
          </Col>
          <Col xs="12" sm="6" lg="3">
            <MonitorCard
                title="Router Monitor"
                toolTip="List of displays with high data usage or recent router reboot"
                data={dataRouter}
                dataDetails={DetailsRouter}
                statusEnum={ROUTER_MONITOR_STATUS}
                chart={ChartDataUsage}
                chartData={this.props.data}
                chartTitle="Displays Current Data Usage"
              />
          </Col>
          <Col xs="12" sm="6" lg="3">
            <MonitorCard
                title="Ping Monitor"
                toolTip="List of displays where the ping online status is different from the display online status.\nExamples:\nWHEN Display is online    AND TeamViewer OR WebCam OR ISP is offline\nWHEN Display is offline    AND TeamViewer OR Router OR WebCam OR ISP is online"
                data={dataPing}
                dataShort={DetailsPingShort}
                statusEnum={utils.ONLINE_STATUS}
                showMonitorStatus={false}
              />
          </Col>
          <Col xs="12" sm="6" lg="3">
            <MonitorCard
                title="Snapshots Monitor"
                toolTip="List of displays with failed snapshots.\nExample:\nWHEN Display is monitored AND installed AND online\nAND snapshot is missing OR size is 0 OR is older than 30min"
                data={dataSnapshots}
                statusEnum={utils.SNAPSHOT_STATUS}
              />
          </Col>
        </Row>
      </div>
    );
  }
}

function getStats(display, monitor, camera) {
  if (_.isEmpty(monitor)) return {}

  const displays = getDisplays(_.get(display, "displays"))
  const tempStats = getTempStats(displays, monitor)
  const tzStats = getTZStats(displays)
  const offlineStats = getOfflineStats(displays)
  const appsStats = getAppsStats(_.get(monitor, "stats.PcHealth"))
  const pingStats = getPingStats(displays)

  return {
        Camera: parseStats(_.get(monitor, "stats.CameraMonitor"), displays),
        PC: parseStats(_.get(monitor, "stats.PcHealth"), displays),
        Router: parseStats(_.get(monitor, "stats.Router"), displays),
        Apps: parseStats(appsStats, displays),
        Temperatures: parseStats(tempStats, null, getTemperatureStatFn),
        TZ: parseStats(tzStats, null, getTzStatFn),
        Offline: parseStats(offlineStats, displays, getOfflineStatFn, null),
        Ping: parseStats(pingStats, null, getPingStatFn, null),
        Snapshots: parseStats(_.get(camera, "snapshotStats"), displays, getSnapshotStatFn),
      }
}
function parseStats(stats, displays, getStatFn = getMonitorStatFn, filterFn = filterOnline) {
  if (!stats || !stats.length) return null;

  const filters = { sortAsc: false }
  const sortNum = (a, b) => {
    let aNaN = isNaN(a)
    let bNaN = isNaN(b)
    if (aNaN && bNaN) return 0
    else if (aNaN)    return -b
    else if (bNaN)    return a
    else              return a - b
  }
  const sortStr = (a, b) => {
    if (!a && !b) return 0
    else if (a > b) return 1
    else if (a < b) return -1
    else return 0
  }
  const sortFunc = (a, b) => {
    let result = 0

    // first sort by Display Status
    if (a.DisplayStatus < b.DisplayStatus) result = -1;
    if (a.DisplayStatus > b.DisplayStatus) result = 1;

    // then  sort by Date
    if (result === 0) result = sortStr(a.UpdatedAt, b.UpdatedAt)

    // then  sort by DisplayID
    if (result === 0) result = sortNum(a.display_id, b.display_id)

    return ((filters.sortAsc) ? 1 : -1) * result
  }

  let r = stats
          .filter(o => o && (typeof o.Status === 'object' || o.Status > 0) )
          .map(o => {
            const {displayId, d, stat} = getStatFn(o, displays) 
            // const {displayId, d, stat} = (typeof getStatFn === "function") 
            //                               ? getStatFn(o, displays) 
            //                               : getMonitorStatFn(o,displays)  // default proces
            const cameraId = _.get(d, "Cameras[0].CameraId")

            return {
              // stats fileds
              DisplayId: displayId,
              UpdatedAt: stat.UpdatedAt,
              Status: stat.Status,  // Stats status
              Offline: (Date.now() - stat.UpdatedAt) > 61 * 60 * 1000,   // 61 min (in ms)
              Stats: stat.Stats,
              
              // display fields
              Address: d.Address,
              ProjectName: d.ProjectName || "",
              DisplayName: d.DisplayName || "",
              CompanyName: d.CompanyName || "",
              SerialNumber: d.SerialNumber || "",
              RemoteAccessIP: _.get(d, "RemoteAccess.Ip") || "",
              Error: null,
              TotalAlerts: _.get(d, "Status.AlertCount.Total") || 0,
              DisplayStatus: _.get(d, "Status.SemanticStatus.State") || "",
              Vop: _.get(d, "Status.OperatingPercentage.Visual") || "",
              // SnapTimestamp: null,
              // SnapSize: null,
              SnapPath: (cameraId) ? `/${cameraId}/latest.jpg?${Date.now()}` : null
            }
          })
    
    if (filterFn) r = r.filter(filterFn)
    return r.sort(sortFunc)
}

function getDisplays(displays) {
  // remove VIQ 1 displays (temporary solution. Later on VIQ1 online status should be based on the WebCam/Router PING)
  if (!displays) return displays
  else return displays.filter(d => !VIQ1_DISPLAY_IDS.includes(d.DisplayId) && !_.get(d, "Servicing.on", false))
}
function getTempStats(displays, monitor) {
  if (!displays) return []
  let pcData = utils.getMonitorMap(_.get(monitor, "stats.PcHealth"))
  let rtrData = utils.getMonitorMap(_.get(monitor, "stats.Router"))

  // for each display add 'StatsTemperature'
  let tempStats = []
  for(let i = displays.length; i--; ){
    let d = utils.addMonitorTemps(displays[i], pcData, rtrData)
    const statsTemp = utils.marshallTempStat(d)
    if (statsTemp.StatsTemperature.Status === 0) continue   // go to next Display
    tempStats.push(Object.assign(d, statsTemp))
  }
  return tempStats
}
function getOfflineStats(displays) {
  if (!displays) return []
  let stats = []
  for(let i = displays.length; i--; ){
    let d = displays[i]
    if (d) {
      const state = _.get(d, "Status.SemanticStatus.State") || ""
      if (state.toLowerCase().indexOf("offline") === 0) {
        stats.push(Object.assign(d,utils.marshallOfflineStat(d)))
      }
    }
  }
  return stats
}
function getTZStats(displays) {
  if (!displays) return []
  let tzStats = []
  for(let i = displays.length; i--; ){  // reverse loop is 3x faster than normal loop
    let d = displays[i]
    if (d && !d.Timezone) {
      tzStats.push(Object.assign(d,utils.marshallTZStat(d)))
    }
  }
  return tzStats
}

function getPingStats(displays) {
  // Show the display
  //  WHEN Display is online    AND TeamViewer OR WebCam OR ISP is offline
  //  WHEN Display is offline   AND TeamViewer OR WebCam OR ISP is online OR Router 
  if (!displays) return []
  let results = []
  
  for(let i = displays.length; i--; ){
    const d = displays[i]

    // Skip displays that are not 'online' or 'offline'
    const displayStatus = (_.get(d, "Status.SemanticStatus.State") || "").toLowerCase()
    if (!['online', 'offline'].includes(displayStatus)) continue;
    const displayOnline = displayStatus.indexOf("online") === 0
    
    let rtrOnline = -1      // [-1 Null, 0 False, 1 True]
    let someCamOnline = -1  
    let someCamOffline = -1
    let someSimOnline = -1  
    let someSimOffline = -1
    let teamviewerOnline = -1

    // get TeamViewer status
    let teamviewerStatus = _.get(d, "Teamviewer.Status") || ""
    let teamviewerId = _.get(d, "RemoteAccess.Teamviewer", 0)
    teamviewerOnline = teamviewerId > 0 
                      ? (teamviewerStatus.toLowerCase().indexOf("online") === 0)  
                        ? 1 : (teamviewerStatus.toLowerCase().indexOf("offline") === 0) 
                        ? 0 : -1
                      : -1

    // get SIM status
    let sim = d.SIM
    if (typeof d.SIM !== 'undefined') {
      for (const prop in sim) {
        const simStatus = sim[prop][0]
        const simUpdateUx = sim[prop][1] * 1000
        if (moment().diff(moment.utc(simUpdateUx), "months") < 1) { // if data is too old ignore it
          if (typeof simStatus !== 'undefined') {
            if (simStatus) someSimOnline = 1
            else someSimOffline = 0
          }
        }
      }
    }

    // get WebCams status
    let cameras = _.get(d, "Cameras")
    let webcams = _.get(d, "Ping.Cam")
    if (cameras) {
      for (const prop in webcams) {
        const camStatus = webcams[prop][0]
        const camUpdateUx = webcams[prop][1] * 1000
        if (moment().diff(moment.utc(camUpdateUx), "months") < 1) { // if data is too old ignore it
          if (typeof camStatus !== 'undefined') {
            if (camStatus) someCamOnline = 1
            else someCamOffline = 0
          }
        }
      }
    }

    // get Router status
    const rtrUpdateUx = _.get(d, "Ping.Rtr[1]", 0) * 1000
    rtrOnline = (moment().diff(moment.utc(rtrUpdateUx), "days") < 3)  // if data is too old ignore it
                ? (someCamOnline > 0) ? 1 : Number(_.get(d, "Ping.Rtr[0]", -1))
                : -1
    
    // should be added
    let showIt = (displayOnline) ? (teamviewerOnline === 0 || someSimOffline === 0 || someCamOffline === 0)
                                 : (Math.max(teamviewerOnline, rtrOnline, someSimOnline, someCamOnline) === 1)

    const PingStats = {
      Teamviewer: ((teamviewerOnline === -1) ? null : [teamviewerOnline===1, _.get(d, "Teamviewer.Lastseen")]),
      SIM: ((someSimOnline === -1 && someSimOffline === -1) ? null : _.get(d, "SIM")),
      Router: ((rtrOnline === -1) ? null : _.get(d, "Ping.Rtr")),
      WebCams: ((someCamOnline === -1 && someCamOffline === -1) ? null : _.get(d, "Ping.Cam")),
    }
    if (showIt) {
      results.push(Object.assign(d,utils.marshallPingStat(d, PingStats)))
    }
  }

  return results
}
function getAppsStats(stats) {  // returns any display that has any app crashed recently
  if (!stats) return []
  let appStats = []
  const minTs = (Date.now() - (APPS_GRACE_PERIOD_IN_HOUR * 60 * 60 * 1000))

  for(let i = stats.length; i--; ){ 
    let item = stats[i]    
    const crashes = _.get(item, "Stats.Crashes")
    if (!crashes) continue;
    
    let found = false
    for(let j = crashes.length; j--; ) {
      let app = crashes[j]
      if (app) {
        let ts = Number(app.TS) || minTs
        if (ts >= minTs) {
          found = true
          break;
        }
      }
    }
    if (found) {
      appStats.push(Object.assign({}, item, {Status: APP_MONITOR_STATUS.APP_FAILURE}))
    }
  }
  return appStats
}

function getOfflineStatFn(d, _x) {
  return {
    d:          d,
    stat:       d.StatsOffline,
    displayId:  d.DisplayId,
  }
}
function getTzStatFn(d, _x) {
  return {
    d:          d,
    stat:       d.StatsTZ,
    displayId:  d.DisplayId,
  }
}
function getPingStatFn(d, _x) {
  return {
    d:          d,
    stat:       d.StatsPing,
    displayId:  d.DisplayId,
  }
}
function getTemperatureStatFn(d, _x) {
  return {
    d:          d,
    stat:       d.StatsTemperature,
    displayId:  d.DisplayId,
  }
}
function getSnapshotStatFn(stat, displays) {
  const displayId = stat.DisplayId
  return {
    d:          (displays || []).find(d => d.DisplayId === displayId) || {},
    stat:       stat,
    displayId:  displayId,
  }
}
function getMonitorStatFn(stat, displays) {
  // AppId: "cm", AppKey: "sta-now-1105", UpdatedAt: 1595420527311, Status: 1
  const displayId =_.toInteger(stat.AppKey.split("-")[2])
  return {
    d:          (displays || []).find(d => d.DisplayId === displayId) || {},
    stat:       stat,
    displayId:  displayId,
  }
}

let filterOnline = o => o.DisplayStatus === "online" 
if (DEBUG_DASHBOARD) {
  filterOnline = o => o.DisplayStatus === "online" || o.DisplayId === 1105; // 1105 Test display
}
function mapStateToProps(state) {
  const { display, monitor, camera } = state
  const { user } = state.authentication
  const {
    stats,
    loading,
    error
  } = {
    stats: getStats(display, monitor, camera),
    loading: _.get(display, "loading") || _.get(monitor, "loading") || _.get(camera, "loading"),
    error: _.get(display, "error") || _.get(monitor, "error") || null,
  };
  return { data: { display, monitor }, stats, user, loading, error }
}
const connectedDashboard = connect(mapStateToProps)(Dashboard);
export default connectedDashboard;
