import React, { Component } from 'react';
import { Badge, Card, CardBody, CardHeader, Table, Button, Input } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import _ from "lodash"
import {
  MdGridOn,
  MdRadioButtonUnchecked,
  MdBrightness6,
  MdSignalWifiOff,
  MdExitToApp,
  MdSettingsEthernet,
  MdWhatshot,
  MdSystemUpdate,
  MdVideoLibrary,
  MdVpnKey,
  MdLocalShipping,
  MdLiveTv,
  MdStarOutline,
  MdStar,
  MdBuild,
  MdOutlineBuild,
  MdMailOutline,
} from 'react-icons/md';
import {FaWifi} from 'react-icons/fa';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AppSwitch } from '@coreui/react'
import "spinkit/css/spinkit.css"
import { utils } from "../../../utils";
import { displayActions } from "../../../api/_actions";
import DisplayFieldEdit from "./DisplayFieldEdit";
import DetailsPC from "../../../_components/monitor/detailsPC"
import { TempShort } from "../../../_components/monitor/detailsTempShort"
import { StatusBadge } from "../../../_components/StatusBadge"

class DisplayDetail extends Component {

  componentDidMount() {
    this.interval = setInterval(() =>
    {
      const { selectedDisplay } = this.props;
      if (selectedDisplay) {
        this.props.dispatch(displayActions.getDetail(selectedDisplay));
        this.props.dispatch(displayActions.getSingleSummary(selectedDisplay));
      }
    }, 30000);

    const { selectedDisplay } = this.props;
    if (selectedDisplay) {
      this.props.dispatch(displayActions.getDetail(selectedDisplay));
      this.props.dispatch(displayActions.getSingleSummary(selectedDisplay));
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.interval = null
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedDisplay } = this.props;
    if (selectedDisplay !== prevProps.selectedDisplay) {
      this.props.dispatch(displayActions.getDetail(selectedDisplay));
      this.props.dispatch(displayActions.getSingleSummary(selectedDisplay));
    }
  }

  populateVersions = (versionCol) => {
    let retVal = [];
    for (let key in versionCol) {
      if (versionCol.hasOwnProperty(key)) {
        retVal.push(<span key={key} style={{minWidth: 30 +'px'}} className="badge p-1 mx-2 badge-undef" data-toggle="tooltip" data-placement="top" title={"Count: " + versionCol[key]}>
            {key}
        </span>)
      }
    }

    return retVal;
  };

  render() {
    const { display, user } = this.props;
    const fakeFailoverModule = (display && display.Status && display.Status.ComponentStatus) ? display.Status.ComponentStatus.Module.Failover + display.Status.ComponentStatus.Module.Unknown : 0;
    const fakeOfflineModule = (display && display.Status && display.Status.ComponentStatus) ? display.Status.ComponentStatus.Module.Offline + display.Status.ComponentStatus.Module.Absent : 0;
    const favoriteStatus = (display && display.Favorite);
    const serviceStatus = _.get(display, "Servicing.on", false);
    const serviceUser = _.get(display, "Servicing.usr", "");
    const serviceExpNum = _.get(display, "Servicing.exp");
    const serviceExp = (serviceExpNum) ? utils.timeAgo(moment.unix(serviceExpNum).utc()).description : ""
    const oaaa = (display && display.PanelId);
    const lastBeat = moment.tz(display && display.Status && display.Status.Lastbeat, "YYYY-MM-DD hh:mm:ss", "UTC").tz(moment.tz.guess())
    const lastBeatAgo = utils.timeAgo(lastBeat)
    const installDate = moment((display && display.InServiceDate) || "", "YYYY-MM-DD hh:mm:ss")
    const installed = moment().diff(installDate, 'minutes') > 0
    const canUpdate = user && user.permissions && user.permissions.canUpdate(utils.Resources.displayDetails)
    const statsTemp = _.get(display, "StatsTemperature.Stats")
    const latestSynapseVer = (_.get(display, "Status.Software.Synapse") || "").substring(0,1) === "2" ? "2.5.16" : "3.2.0"
    const latestTeamviewerVer = (_.get(display, "RemoteAccess.Teamviewer", "0") !== "0") ? "15.11.6.0" : _.get(display, "Status.Software.Teamviewer")

    // get Teamviewer status
    
    const teamviewerStatus = _.get(display, "Teamviewer.Status")
    const teamviewer = (typeof teamviewerStatus !== 'undefined') 
                        ? [teamviewerStatus.toLowerCase().indexOf("online") === 0, _.get(display, "Teamviewer.Lastseen")]
                        : null
    const displayFullName = (display && ((display.ProjectName || "") + " # " + (display.DisplayName || ""))) || ""

    let lowest = 100;
    if (display && display.Status && display.Status.Brightness) {
      lowest = Math.min(...display.Status.Brightness);
    }
    return (
      <div className="animated fadeIn h-100">
            <Card className="h-auto mb-0">
              <CardHeader>
                <i className="fa fa-align-justify" data-toggle="tooltip" data-placement="top" title={display && display.DisplayId}></i>Display Detail<small className="text-muted ml-2" title={displayFullName}>{displayFullName}</small>
                <div className="d-inline float-right">
                  {canUpdate && 
                    <React.Fragment>
                      <DisplayFieldEdit 
                        key={`fev-${display && display.DisplayId}`}
                        fieldName="Favorite" 
                        hideButtons={true}
                        display={display}
                        className="d-inline float-right"
                        render={(displayObj, onFieldChanged) => (
                          <Button   type="button"
                                    className={"btn btn-transparent p-0 text-warning "}
                                    value={favoriteStatus}
                                    onClick={onFieldChanged}
                                    title={`Favorite is ${(favoriteStatus) ? "ON" : "OFF"}`}>
                            {(favoriteStatus)
                                ? <MdStar size={20} />
                                : <MdStarOutline size={20} />
                            }
                          </Button>
                        )}
                      >
                      </DisplayFieldEdit>
                      <DisplayFieldEdit 
                        key={`svc-${display && display.DisplayId}`}
                        fieldName="Servicing"
                        hideButtons={true}
                        display={display}
                        // image={image}
                        className="d-inline float-right mr-1"
                        render={(_displayObj, onFieldChanged) => (
                          <Button   type="button"
                                    className={"btn btn-transparent p-0 text-warning float-right"}
                                    value={serviceStatus}
                                    disabled={!canUpdate}
                                    onClick={onFieldChanged}
                                    title={(serviceStatus) ? `Servicing in progress ON\n` +
                                                              `Serviced by '${serviceUser}'\n` +
                                                              `Expires ${serviceExp}`
                                                          :"Servicing in progress OFF"}>
                            {(serviceStatus)
                                ? <MdBuild size={16} />
                                : <MdOutlineBuild size={16} />
                            }
                          </Button>
                        )}
                      >
                      </DisplayFieldEdit>
                    </React.Fragment>
                  }
                  <Button   type="button"
                            className={"btn btn-transparent p-0 float-right text-warning mr-2"}
                            onClick={utils.onCreateTicket.bind(this, display)}
                            title="Report an issue">
                    <MdMailOutline size={20} />
                  </Button>
                  </div>
              </CardHeader>
              <CardBody>
                <Table responsive size="sm" className="mb-0 overflow-hidden">
                  <thead>
                  <tr className="card-header">
                    <th>Component</th>
                    <th>Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td><MdRadioButtonUnchecked size={20} color="gray"/> Module</td>
                    <td>
                      <Badge className="detail-badge mx-1 p-1" color="danger" data-toggle="tooltip" data-placement="top" title={display && display.Status && display.Status.ComponentStatus && "Absent: " + display.Status.ComponentStatus.Module.Absent} >
                        {display && display.Status && display.Status.ComponentStatus  && "Offline: " + fakeOfflineModule}
                      </Badge>
                      <Badge className="detail-badge mx-1 p-1" color="warning" data-toggle="tooltip" data-placement="top" title={display && display.Status && display.Status.ComponentStatus && "Uncertainty: " + display.Status.ComponentStatus.Module.Unknown} >
                        {display && display.Status && display.Status.ComponentStatus  && "Failover: " + fakeFailoverModule}
                      </Badge>
                      <Badge className="detail-badge mx-1 p-1" color="success">{display && display.Status && display.Status.ComponentStatus && "Online: " +  display.Status.ComponentStatus.Module.Online}</Badge>
                      <Badge className="detail-badge mx-1 p-1" color="primary">{display && display.Status && display.Status.ComponentStatus  && "Total: " +  display.Status.ComponentStatus.Module.Total}</Badge>
                    </td>
                  </tr>
                  <tr>
                    <td><MdGridOn size={20} color="gray"/> Receiver</td>
                    <td>
                      <Badge className="detail-badge mx-1 p-1" color="danger">{display && display.Status && display.Status.ComponentStatus && "Offline: " + display.Status.ComponentStatus.Receiver.Offline}</Badge>
                      <Badge className="detail-badge mx-1 p-1" color="warning">{display && display.Status && display.Status.ComponentStatus && "Failover: " + display.Status.ComponentStatus.Receiver.Failover}</Badge>
                      <Badge className="detail-badge mx-1 p-1" color="success">{display && display.Status && display.Status.ComponentStatus && "Online: " + display.Status.ComponentStatus.Receiver.Online}</Badge>
                      <Badge className="detail-badge mx-1 p-1" color="primary">{display && display.Status && display.Status.ComponentStatus && "Total: " + display.Status.ComponentStatus.Receiver.Total}</Badge>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-nowrap"><MdSignalWifiOff size={20} color="gray"/> Last Checked In</td>
                    <td>
                      {display && display.Status && display.Status.SemanticStatus &&
                      <span>
                        <Badge className="detail-badge mx-1 p-1 mr-2" color={display.Status.SemanticStatus.State === "online" ? "success" : "danger"}>
                          {display.Status.SemanticStatus.State === "online" ? "Online" : "Offline"}
                        </Badge>
                        {lastBeatAgo.title} <small>{lastBeatAgo.description}</small>
                      </span>
                      }
                    </td>
                  </tr>
                  <tr>
                    <td><MdVideoLibrary size={20} color="gray"/> Video Signal</td>
                    <td>
                      {display && display.Status &&
                      <span>
                      <Badge className="detail-badge mx-1 p-1 mr-4" color={display.Status.DviState ? "success" : "danger"}>
                        {display.Status.DviState ? "Online" : "Offline"}
                      </Badge>
                        </span>
                      }
                    </td>
                  </tr>
                  <tr>
                    <td><MdBrightness6 size={20} color="gray"/> Brightness</td>
                    <td>
                      <span>
                        {display && display.Status && display.Status.Brightness && display.Status.Brightness.map((val, index) =>
                          <span key={index} style={{minWidth: 30 +'px'}} className={"badge p-1 mx-1 badge-" + (val === lowest ? "success" : "warning")}>
                            {val}
                          </span>
                        )}
                      </span>
                    </td>
                  </tr>
                  <tr>
                  <td><MdExitToApp size={20} color="gray"/> Teamviewer</td>
                  <td>
                    {display && display.RemoteAccess &&
                    <React.Fragment>
                      <CopyToClipboard text={display.RemoteAccess.Teamviewer}>
                        <Button
                          href={"https://start.teamviewer.com/device/" + display.RemoteAccess.Teamviewer + "/authorization/password/mode/control"}
                          target={"_blank"}
                          color="ghost-light"
                          className="py-0 px-1 text-left col-3"
                        >
                          {(display.RemoteAccess.Teamviewer !== "0") ? display.RemoteAccess.Teamviewer : "N/A"}</Button>
                      </CopyToClipboard>
                      <StatusBadge label="Teamviewer" value={teamviewer} className="d-inline ml-1" hideLabel={true} hideSince={true} />
                    </React.Fragment>
                    }
                  </td>
                  </tr>
                  <tr>
                    <td><MdSettingsEthernet size={20} color="gray"/> WAN IP</td>
                    <td>
                      {display && display.RemoteAccess &&
                      <React.Fragment>
                        <CopyToClipboard text={display.RemoteAccess.Ip}>
                          <Button color="ghost-light" className="py-0 px-1 text-left col-3 text-nowrap">{display.RemoteAccess.Ip}</Button>
                        </CopyToClipboard>
                        <StatusBadge label="Router" value={_.get(display, "Ping.Rtr")} className="d-inline ml-1" hideLabel={true} hideSince={true} />
                      </React.Fragment>
                      }
                    </td>
                  </tr>
                  {display && display.SIM &&
                  <tr>
                    <td><FaWifi size={20} color="gray"/> SIM</td>
                    <td>
                      {Object.keys(display.SIM).map(key => {
                        const item = display.SIM[key]
                        const [ , , ip, isp, apn] = item
                        return (
                          <React.Fragment key={key}>
                            <CopyToClipboard text={ip}>
                              <Button color="ghost-light" className="py-0 px-1 text-left col-3 text-nowrap">{ip}</Button>
                            </CopyToClipboard>
                            <StatusBadge label="ISP" value={item} className="d-inline ml-1" hideLabel={true} hideSince={true} />
                            <small className="ml-2" title="ISP">{isp} -</small>
                            <small className="ml-1" title="APN">{apn}</small>
                          </React.Fragment>
                        )
                      })}
                    </td>
                  </tr>
                  }
                  <tr>
                    <td><MdVpnKey size={20} color="gray"/> API Key</td>
                    <td>
                      {display && display.RemoteAccess &&
                      <CopyToClipboard text={display.RemoteAccess.ApiKey}>
                        <Button color="ghost-light" className="py-0 px-1 text-left">{display.RemoteAccess.ApiKey}</Button>
                      </CopyToClipboard>
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <MdLocalShipping size={20} color="gray"/> Install date
                    </td>
                    <td>
                      {display && display.InServiceDate && 
                        <span className="form-inline">
                          <Badge className="detail-badge mx-1 p-1 mr-4" color={installed ? "success" : "danger"}>
                            {(installed ? "Installed" : "Not-installed")}
                          </Badge>
                          {canUpdate ?
                            (<DisplayFieldEdit 
                              fieldName="InServiceDate" 
                              display={display}                           
                              render={(displayObj, onFieldChanged) => (
                                <Input  type="date" placeholder="date" 
                                        className="px-2" style={{width:150+'px', height: 'calc(1.5em + 0.15rem + 2px)'}}
                                        value={moment.tz(displayObj.InServiceDate, moment.tz.guess()).format(moment.HTML5_FMT.DATE)}
                                        onChange={onFieldChanged}
                                        title={moment.tz(displayObj.InServiceDate || "", moment.tz.guess()).format("YYYY-MM-DD H:mm:ss z")}
                                />
                              )}
                              />)
                          : (
                            <div>
                              {installDate.format("YYYY-MM-DD H:mm:ss z")}
                            </div>
                          )}

                        </span>
                      }
                    </td>
                  </tr>                  
                  <tr>
                    <td><MdLiveTv size={20} color="gray"/> Monitored</td>
                    <td>
                      <span className="m-0 p-0" title={display ? "Display is " + (!display.Monitored ? "NOT-" : "") +  "monitored" : ""}>
                        <DisplayFieldEdit 
                          fieldName="Monitored" 
                          hideButtons={true}
                          display={display} 
                          render={(displayObj, onFieldChanged) => (
                            <AppSwitch  className={'mx-1, align-middle'} variant={'pill'} color={'primary'} label size="sm" 
                                        disabled={!canUpdate}
                                        checked={display && display.Monitored} 
                                        onClick={onFieldChanged}
                            />
                          )}
                        >
                          <span className="align-middle ml-2" title="Camera External (WAN) IP and Port">
                            {_.get(display, "Cameras[0].Ip") 
                            ? `${_.get(display, "Cameras[0].Ip")}:${_.get(display, "Cameras[0].Port")}` 
                            : <small className="text-danger">no camera</small>
                            } 
                          </span>
                          <StatusBadge label="WebCam" value={_.get(display, "Ping.Cam")} className="d-inline ml-2" hideLabel={true} hideSince={true} />
                        </DisplayFieldEdit>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-nowrap" title="Average Temperatures"><MdWhatshot size={20} color="gray"/> Temperatures</td>
                    <td>
                      <span>
                        <TempShort item={statsTemp} display={display}/>
                      </span>
                    </td>
                  </tr>
              <tr className="card-header">
                <th colSpan={2}>Port Firmware</th>
              </tr>
              <tr>
                <td><MdSystemUpdate size={20} color="gray"/> CPU</td>
                <td>
                      <span>
                        {display && display.Status && display.Status.Firmware && display.Status.Firmware.Port && display.Status.Firmware.Port.Cpu
                        && this.populateVersions(display.Status.Firmware.Port.Cpu)}
                      </span>
                </td>
              </tr>
              <tr>
                <td><MdSystemUpdate size={20} color="gray"/> FPGA</td>
                <td>
                      <span>
                        {display && display.Status && display.Status.Firmware && display.Status.Firmware.Port && display.Status.Firmware.Port.Fpga
                        && this.populateVersions(display.Status.Firmware.Port.Fpga)}
                      </span>
                </td>
              </tr>
              <tr className="card-header">
                <th colSpan={2}>Receiver Firmware</th>
              </tr>
              <tr>
                <td><MdSystemUpdate size={20} color="gray"/> CPU</td>
                <td>
                      <span>
                        {display && display.Status && display.Status.Firmware && display.Status.Firmware.Module && display.Status.Firmware.Receiver.Cpu
                        && this.populateVersions(display.Status.Firmware.Receiver.Cpu)}
                      </span>
                </td>
              </tr>
              <tr>
                <td><MdSystemUpdate size={20} color="gray"/> FPGA</td>
                <td>
                      <span>
                        {display && display.Status && display.Status.Firmware && display.Status.Firmware.Module && display.Status.Firmware.Receiver.Fpga
                        && this.populateVersions(display.Status.Firmware.Receiver.Fpga)}
                      </span>
                </td>
              </tr>
              <tr>
                <td><MdSystemUpdate size={20} color="gray"/> PCB</td>
                <td>
                      <span>
                        {display && display.Status && display.Status.Firmware && display.Status.Firmware.Module && display.Status.Firmware.Receiver.Pcb
                        && this.populateVersions(display.Status.Firmware.Receiver.Pcb)}
                      </span>
                </td>
              </tr>
              <tr>
                <td><MdSystemUpdate size={20} color="gray"/> Hub</td>
                <td>
                      <span>
                        {display && display.Status && display.Status.Firmware && display.Status.Firmware.Module && display.Status.Firmware.Receiver.Hub
                        && this.populateVersions(display.Status.Firmware.Receiver.Hub)}
                      </span>
                </td>
              </tr>
              <tr className="card-header">
                <th colSpan={2}>Module Firmware</th>
              </tr>
              <tr>
                <td><MdSystemUpdate size={20} color="gray"/> CPU</td>
                <td>
                      <span>
                        {display && display.Status && display.Status.Firmware && display.Status.Firmware.Module && display.Status.Firmware.Module.Cpu
                        && this.populateVersions(display.Status.Firmware.Module.Cpu)}
                      </span>
                </td>
              </tr>
              <tr className="card-header">
                <th colSpan={2}>
                    Software
                    <DetailsPC display={display} autoLoad={true}></DetailsPC>
                </th>
              </tr>
              {display && display.Status && display.Status.Software && 
              <React.Fragment>
              <tr>
                <td><MdSystemUpdate size={20} color="gray"/> Synapse</td>
                <td>
                  <span>
                    {display.Status.Software.Synapse && 
                      <span style={{minWidth: 30 +'px'}} className={`badge p-1 mx-2 ${(latestSynapseVer !== display.Status.Software.Synapse) ? "badge-danger" : "badge-undef"}`} data-toggle="tooltip" data-placement="top" title={`Expected: "${latestSynapseVer}"\nActual: "${display.Status.Software.Synapse}"`}>
                        {display.Status.Software.Synapse}
                      </span>
                    }
                  </span>
                </td>
              </tr>
              <tr>
                <td><MdSystemUpdate size={20} color="gray"/> TeamViewer</td>
                <td>
                  <span>
                    {display.Status.Software.Teamviewer && 
                      <span style={{minWidth: 30 +'px'}} className={`badge p-1 mx-2 ${latestTeamviewerVer !== display.Status.Software.Teamviewer ? "badge-danger" : "badge-undef"}`} data-toggle="tooltip" data-placement="top" title={`Expected: "${latestTeamviewerVer}"\nActual: "${display.Status.Software.Teamviewer}"`}>
                        {display.Status.Software.Teamviewer}
                      </span>
                    }
                  </span>
                </td>
              </tr>
              <tr>
                <td><MdSystemUpdate size={20} color="gray"/> Player</td>
                <td>
                  <span>
                    {display.Status.Software.Player && 
                      <span style={{minWidth: 30 +'px'}} className="badge p-1 mx-2 badge-undef" data-toggle="tooltip" data-placement="top" title={display.Status.Software.Player}>
                        {display.Status.Software.Player}
                      </span>
                    }
                  </span>
                </td>
              </tr>
              </React.Fragment>}
              {oaaa && <React.Fragment>
              <tr className="card-header">
                <th colSpan={2} title="Outdoor Advertising Association of America"> OAAA</th>
              </tr>
              <tr>
                <td><MdSystemUpdate size={20} color="gray"/> API url</td>
                <td className="px-0">
                  {/* <span><a href={`https://${oaaa}.oaaa.mrialerts.com/opi/v1/monitoring`}>{`https://${oaaa}.oaaa.mrialerts.com/opi/v1/monitoring`}</a></span> */}
                  <CopyToClipboard text={`https://${oaaa}.oaaa.mrialerts.com/opi/v1/monitoring`}>
                    <Button
                      href={`https://${oaaa}.oaaa.mrialerts.com/opi/v1/monitoring`}
                      target={"_blank"}
                      color="ghost-light"
                      className="py-0 px-1 ml-1"
                    >
                      {`https://${oaaa}.oaaa.mrialerts.com/opi/v1/monitoring`}</Button>
                  </CopyToClipboard>
                </td>
              </tr>
              </React.Fragment>}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </div>

    );
  }
}
function parseDisplayTemp(d) {
  // add Temperature Stats
  if (!d) return
  const statsTemp = utils.marshallTempStat(d)
  Object.assign(d, statsTemp)
}
function mapStateToProps(state) {
  const { selectedDisplay, display, saveError, saving } = state.display;
  const { user } = state.authentication;
  parseDisplayTemp(display)
  return {
    selectedDisplay, display, user, saveError, saving
  };

}

const connectedDisplayDetail = connect(mapStateToProps)(DisplayDetail);
export default connectedDisplayDetail;
