import React, { Component } from 'react';
import 'react-dates/initialize';
import _ from 'lodash';
import 'moment-timezone';
import { getStyle, hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities'
import ChartWrap from '../../ChartWrap';
import { ROUTER_FIELDS } from '../../../api/_constants/monitor.router.constants'

const NOT_SET = Symbol("not set")

export default class ChartHotDisplays  extends Component  {
  constructor(props) {
    super(props);

    this.state = {
      params: this.props.params,
      expandStats: false,
      previewSnapshot: null,
      showChart: null,
    }
  }

  toggle = (name, val = NOT_SET) => {
    val = (val === NOT_SET) ? !this.state[name] : val
    this.setState({[name]: val});
  }

  getDatasetOptions = (key, count) => {
    let r = {
      _datasetKey: key, 
      _colorName: "primary",
      label: key,
      borderDash: [],
      borderWidth: 2,
      backgroundColor: 'transparent',
      yAxisID: 'y-axis-log-MB',
      xAxisID: 'x-axis-numeric',
      pointHitRadius: count < 300 ? 5 : 2,
      radius: count < 20 ? 2 : 0,
      unit: " MB"
    }

    if (key === "mu") {
      r.label = "Monthly Usage"
      r._colorName = "success"
    } else if (key === "du") {
      r.label = "Daily Usage"
      r._colorName = "info"
      r.backgroundColor = hexToRgba(getStyle('--info'), 10)
    }

    r.borderColor = getStyle('--' + r._colorName)

    return r
  }
  marshallDataUsage = d => {
    if (!d || !d.Stats) return null

    const dataUnits1     = (Number(d.Stats[ROUTER_FIELDS.CELL_1_PLAN_UNITS.id]) === 1 ? 1024 : 1) * 1024 // [1 - MB, 2 - KB]
    const dataUnits2     = (Number(d.Stats[ROUTER_FIELDS.CELL_2_PLAN_UNITS.id]) === 1 ? 1024 : 1) * 1024 // [1 - MB, 2 - KB]

    const dailyUsage1    = (Number(d.Stats[ROUTER_FIELDS.CELL_1_PREVIOUS_DAILY_USAGE.id]) || 0) * dataUnits1
    const dailyUsage2    = (Number(d.Stats[ROUTER_FIELDS.CELL_2_PREVIOUS_DAILY_USAGE.id]) || 0) * dataUnits2
    const monthlyUsage1  = ((Number(d.Stats[ROUTER_FIELDS.CELL_1_CURRENT_MONTHLY_USAGE.id]) || 0) * dataUnits1)
    const monthlyUsage2  = ((Number(d.Stats[ROUTER_FIELDS.CELL_2_CURRENT_MONTHLY_USAGE.id]) || 0) * dataUnits2)
    const byteToMB = val => val / (1024 * 1024)
    const displayId =_.toInteger((d.AppKey || "").split("-")[2])

    return {
      DisplayId: displayId,
      DailyUsage: {Value: byteToMB(dailyUsage1 + dailyUsage2)},
      MonthlyUsage: {Value: byteToMB(monthlyUsage1 + monthlyUsage2)},
    }
  }
  
  processChartData = (data) => {
    let labelset = [];
    let ds = {du:[], mu:[]};  // Current Daily, Monthly 

    // digest the data
    let sorted = (data || []).map(item => {
      let stat = this.marshallDataUsage(item)
      return {
        DisplayId: item.DisplayId,
        sortVal: parseFloat(_.get(stat,"MonthlyUsage.Value", 0)) || 0,
        ...stat,
      }
    })
    .sort((a,b) => {return b.sortVal - a.sortVal})    
    for (let i = 0; i < sorted.length; i++) {
      const stat = sorted[i];
      labelset.push(stat.DisplayId)
      ds.du.push({x: stat.DisplayId, y:_.get(stat,"DailyUsage.Value")})
      ds.mu.push({x: stat.DisplayId, y:_.get(stat,"MonthlyUsage.Value")})
    }

    // set datasets
    let datasets = []
    for (const key in ds) {
        const options = this.getDatasetOptions(key, ds[key] && ds[key].length)
        datasets.push({
          ...options,
          data: ds[key]
        })
    }
    
    return {
      datasets: datasets,
      labels: labelset
    }
  }

  render() {
    const yLabel = `Data usage (MB)`
    const chartData = this.processChartData(_.get(this.props, "data.monitor.stats.Router"))
    
    return (
        <div className="chart-container vhd-100" style={{height: "60vh"}}>
            <ChartWrap data={chartData} xLabel={"Displays"} yLabel={yLabel}/>
        </div>
    )
  }
};
