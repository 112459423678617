import { monitorConstants } from '../_constants';
import { ROUTER_FIELDS, ROUTER_MONITOR_STATUS } from "../_constants/monitor.router.constants";

export function monitor(state = {}, action) {
    const pc_key = (action && action.displayId)

    switch (action.type) {
        case monitorConstants.GET_STATS_REQUEST:
            return Object.assign({}, state, {
                loading: true,
                // history: null,
                // latestSnapshots: null,
                error: null
            });
        case monitorConstants.GET_STATS_SUCCESS:
            calcRouterStatus(action.stats)
            return Object.assign({}, state, {
                loading: false,
                stats: action.stats,
                // latestSnapshots: null,
                error: null
            });
        case monitorConstants.GET_STATS_FAILURE:
            return Object.assign({}, state, {
                loading: false,
                stats: null,
                // latestSnapshots: null,
                error: action.error
            });

        case monitorConstants.GET_PC_REQUEST:
            return Object.assign({}, state, {
                loading: true,
                error: null
            });
        case monitorConstants.GET_PC_SUCCESS:
            return Object.assign({}, state, {
                [pc_key]: action.stats,
                loading: false,
                error: null
            });
        case monitorConstants.GET_PC_FAILURE:
            return Object.assign({}, state, {
                [pc_key]: null,
                loading: false,
                error: action.error
            });

        case monitorConstants.GET_ROUTER_REQUEST:
            return Object.assign({}, state, {
                loading: true,
                error: null
            });
        case monitorConstants.GET_ROUTER_SUCCESS:
            calcRouterStatus(action.stats)
            return Object.assign({}, state, {
                [pc_key]: action.stats,
                loading: false,
                error: null
            });
        case monitorConstants.GET_ROUTER_FAILURE:
            return Object.assign({}, state, {
                [pc_key]: null,
                loading: false,
                error: action.error
            });
    
        case monitorConstants.GET_ISP_REQUEST:
            return Object.assign({}, state, {
                loading: true,
                error: null
            });
        case monitorConstants.GET_ISP_SUCCESS:
            return Object.assign({}, state, {
                [pc_key]: action.stats,
                loading: false,
                error: null
            });
        case monitorConstants.GET_ISP_FAILURE:
            return Object.assign({}, state, {
                [pc_key]: null,
                loading: false,
                error: action.error
            });
    

        default:
            return state
    }
}

function calcRouterStatus(items) {
    // --------------------------------------------
    // For each item determinate the Router status:
    // --------------------------------------------
    // high daily usage when:   
    //      prevDayUsage1 > dialyLimit1 || 1 GB
    //      prevDayUsage2 > dailyLimit2 || 1 GB
    //      prevDayUsageSum > maxAvgDailyUsage
    // high monthly usage when:
    //      prjMonthlyUsage > (30GB || MonthlyLimit)  (prjMonthlyUsage = Current Monthly Usage / Day of Month * 30)
    // high general usage when there is no data usage monitoring enabled:
    //      totalData/uptimeDays * 30 > (30GB || MonthlyLimit)
    
    items = (items && items.Router) || []
    items = Array.isArray(items) ? items : [items]
    for (let i = items.length; i--;) {

        let item = items[i] 
        if(!item || !item.Stats || 
            Number(item.Status) !== ROUTER_MONITOR_STATUS.OK) {
                continue;
        }
        const data = item.Stats
        try {
            // check uptime - router has to run at least 24 before we can check data usage 
            //                and if it was rebooted less than 3 days ago it should be flagged as RECENTLY_REBOOTED
            const SHORT_UPTIME_LIMIT_MIN = 3 * 24 * 60  // 3 days
            const MIN_UPTIME_MIN         = 24 * 60      // minimum 24h uptime
            const uptime                 = Number(data[ROUTER_FIELDS.DEVICE_UPTIME.id]) || 0
            const dataUsageMonitorON     = (data[ROUTER_FIELDS.CELL_1_USAGE_MONITORING.id] && data[ROUTER_FIELDS.CELL_2_USAGE_MONITORING.id])

            if (uptime < MIN_UPTIME_MIN) {              // skip data usage check
                continue;
            } else if (uptime < SHORT_UPTIME_LIMIT_MIN) {
                item.Status = ROUTER_MONITOR_STATUS.RECENTLY_REBOOTED
            } else if (!dataUsageMonitorON) {
                item.Status = ROUTER_MONITOR_STATUS.DATA_MONITOR_DISABLED
            } else {
                // check data usage     (convert all data units to bytes)
                const MAX_MONTH_DAYS        = 30
                const HIGH_USAGE_PERCENT    = 0.8           // 80 % 
                const DEFAULT_DAILY_LIMIT   = 1073741824    //  1 GB
                const DEFAULT_MONTHLY_LIMIT = 32212254720   // 30 GB
                const dataUnits1            = (Number(data[ROUTER_FIELDS.CELL_1_PLAN_UNITS.id]) === 1 ? 1024 : 1) * 1024              // [1 - MB, 2 - KB]
                const dataUnits2            = (Number(data[ROUTER_FIELDS.CELL_2_PLAN_UNITS.id]) === 1 ? 1024 : 1) * 1024              // [1 - MB, 2 - KB]
                const monthlyLimitUnits1    = (Number(data[ROUTER_FIELDS.CELL_1_MONTHLY_LIMIT_UNITS.id]) === 1 ? 1024 : 1) * 1048576  // [1 - GB, 0 - MB]
                const monthlyLimitUnits2    = (Number(data[ROUTER_FIELDS.CELL_2_MONTHLY_LIMIT_UNITS.id]) === 1 ? 1024 : 1) * 1048576  // [1 - GB, 0 - MB]

                // check monthly usage
                const totalDays              = (new Date()).getDate()
                const monthlyUsage1          = ((Number(data[ROUTER_FIELDS.CELL_1_CURRENT_MONTHLY_USAGE.id]) || 0) * dataUnits1) / totalDays * MAX_MONTH_DAYS
                const monthlyUsage2          = ((Number(data[ROUTER_FIELDS.CELL_2_CURRENT_MONTHLY_USAGE.id]) || 0) * dataUnits2) / totalDays * MAX_MONTH_DAYS
                const monthlyLimit1          = (((Number(data[ROUTER_FIELDS.CELL_1_MONTHLY_LIMIT.id]) || 0) * monthlyLimitUnits1) || DEFAULT_MONTHLY_LIMIT) * HIGH_USAGE_PERCENT
                const monthlyLimit2          = (((Number(data[ROUTER_FIELDS.CELL_2_MONTHLY_LIMIT.id]) || 0) * monthlyLimitUnits2) || DEFAULT_MONTHLY_LIMIT) * HIGH_USAGE_PERCENT
                const highMonthlyUsage       = (monthlyUsage1 > monthlyLimit1 || monthlyUsage2 > monthlyLimit2)
                if (highMonthlyUsage) {
                    item.Status = ROUTER_MONITOR_STATUS.HIGH_DATA_USAGE_MONTHLY
                } else {
                    // check daily usage
                    const dailyUsage1       = (Number(data[ROUTER_FIELDS.CELL_1_PREVIOUS_DAILY_USAGE.id]) || 0) * dataUnits1
                    const dailyUsage2       = (Number(data[ROUTER_FIELDS.CELL_2_PREVIOUS_DAILY_USAGE.id]) || 0) * dataUnits2
                    const dailyLimit1       = (((Number(data[ROUTER_FIELDS.CELL_1_DAILY_LIMIT.id]) || 0) * dataUnits1) || DEFAULT_DAILY_LIMIT) * HIGH_USAGE_PERCENT
                    const dailyLimit2       = (((Number(data[ROUTER_FIELDS.CELL_2_DAILY_LIMIT.id]) || 0) * dataUnits2) || DEFAULT_DAILY_LIMIT) * HIGH_USAGE_PERCENT
                    const avgDailyLimit1  = (monthlyLimit1 / MAX_MONTH_DAYS) || (DEFAULT_DAILY_LIMIT * HIGH_USAGE_PERCENT)
                    const avgDailyLimit2  = (monthlyLimit2 / MAX_MONTH_DAYS) || (DEFAULT_DAILY_LIMIT * HIGH_USAGE_PERCENT)
                    const highDailyUsage = (dailyUsage1 > dailyLimit1    ||  dailyUsage2 > dailyLimit2 ||
                                            dailyUsage1 > avgDailyLimit1 ||  dailyUsage2 > avgDailyLimit2) 
                    if (highDailyUsage) {
                        item.Status = ROUTER_MONITOR_STATUS.HIGH_DATA_USAGE_DAILY
                    } else {
                        // check total usage
                        const dataSent          = Number(data[ROUTER_FIELDS.BYTES_SENT.id]) || 0
                        const dataReceived      = Number(data[ROUTER_FIELDS.BYTES_RECEIVED.id]) || 0
                        const totalUsage        = dataSent + dataReceived
                        const monthlyLimit      = Math.max(monthlyLimit1, monthlyLimit2)
                        const uptimeDays        = uptime / (24 * 60)
                        const prjMonthlyUsage   = totalUsage / uptimeDays * MAX_MONTH_DAYS
                        const highUsage         = (prjMonthlyUsage > monthlyLimit)
                        if (highUsage) {
                            item.Status = ROUTER_MONITOR_STATUS.HIGH_DATA_USAGE
                        }
                    }
                }
            }
        } catch (err) {
            console.error("# Error parsing received data:" , err);
            item.Status = ROUTER_MONITOR_STATUS.ERROR_PARSING_DATA
        }
    }
}