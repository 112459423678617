import _ from 'lodash';
import axios from 'axios';
import 'moment-timezone';
import moment from 'moment';
import { authHeader } from '../_helpers';
import { authService } from './';
import { utils } from '../../utils'
import { config } from '../_config';
const apiUrl = config.apiUrl
//const apiUrl = "http://localhost:3001";  // <---- ONLY for testing

function toQueryString(params) {
  return _.keys(params).map(key => key + '=' + params[key]).join('&');
}

export const cameraService = {
  getHistory,
  getSnapshotStats,
  getLatestSnapshots,
  newSnapshot
};

function getHistory(id, params) {
  id = id || 0
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${apiUrl}/camera/history/${id}?${toQueryString(params)}`,
    requestOptions
  ).then(params && params.zip ? authService.handleResponseBlob : authService.handleResponse);
}

function getLatestSnapshots(params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  return fetch(
    `${apiUrl}/camera/latest/?${toQueryString(params)}`,
    requestOptions
  ).then(authService.handleResponse);
}

function newSnapshot(displayId) {
  const requestOptions = {
    headers: authHeader(),
  };
  
  return axios.post(`${apiUrl}/camera/latest/${displayId}`, null, requestOptions)
  .then(handleResponseAxios)
}

function handleResponseAxios(response) {
  if (response.status === 200) {
    return response.data;
  } else {
    return (response.data && (response.data.message || response.data.error)) || response.statusText;
  }
}

async function getSnapshotStats(displays) {
  // RETURN snapshot info
  // WHEN Display is online
  // AND snapshot is missing OR size is 0 OR is older than 30min

  const MIN_IMG_SIZE = 2048           // 2KB
  const MAX_IMG_SIZE = 81920          // 80KB
  const MAX_SNAPSHOT_AGE_IN_MIN = 30  // 30min
  const REQUEST_TIMEOUT = 12000       // 12sec
  const minDate = moment.utc().add(-MAX_SNAPSHOT_AGE_IN_MIN, 'm')

  let proms = []
  if (displays && displays.length) {
    for (let i = 0; i < displays.length; i++) {
      const d = displays[i]

      // skip offline, not installed and not monitored displays. 
      // check snapshots only for the online displays.
      if (_.get(d, "Status.SemanticStatus.State", "") !== "online") continue;

      const cameras = d.Cameras;
      if (cameras && cameras.length) {

        // make API call to get just HEAD info of the image without the body
        const getHeaderInfo = (image) => {
          const snap_path = `/${image.CameraId}/latest.jpg?${Date.now()}`
          const snapUrl = utils.getSnapUrl(snap_path)

          return utils.fetchTimeout(snapUrl, REQUEST_TIMEOUT, { 
              method: 'HEAD', // *GET, POST, PUT, DELETE, etc.
              cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            })
            .then(function (response) {
              const snap_size =  response.headers.get("Content-Length")
              const imgDate = moment(response.headers.get("Last-Modified"))

              let snap = {
                DisplayId: d.DisplayId,
                CameraId: image.CameraId,
                Status: utils.SNAPSHOT_STATUS.ERROR_S3_INVALID_RESPONSE,
                UpdatedAt: (imgDate.isValid() ? imgDate.valueOf() : undefined),
                Stats: {
                  snap_size,
                }
              }
              if (response.status === 404) {
                snap.Status = utils.SNAPSHOT_STATUS.SNAPSHOT_NOT_FOUND
              } else if (response.status === 200) {

                // set snapshot status
                if (!imgDate.isValid() || imgDate.isBefore(minDate)) {
                  snap.Status = utils.SNAPSHOT_STATUS.SNAPSHOT_IS_OUTDATED
                } else if (snap_size < MIN_IMG_SIZE) {
                  snap.Status = utils.SNAPSHOT_STATUS.FILE_SIZE_SMALL
                } else if (snap_size > MAX_IMG_SIZE) {
                  snap.Status = utils.SNAPSHOT_STATUS.FILE_SIZE_BIG
                } else {
                  snap.Status = utils.SNAPSHOT_STATUS.SNAPSHOT_OK
                }
              }
              return snap
            })
            .catch(function (err) {
              if (err.name === "AbortError") 
                console.error("# GetS3ImageInfo timed out!", err)
              else 
                console.error("# Error getting image header information!", err.message);
            })
        }

        proms.push(...cameras.map(getHeaderInfo))
      }
    }
  }

  return Promise.all(proms)
}
