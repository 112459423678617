import React, { Component } from 'react';
import {
  Card, CardBody, CardHeader,
  Modal, ModalBody, Button,
  ModalHeader
} from 'reactstrap';
import { DateRangePicker } from 'react-dates';
import { connect } from 'react-redux';
import 'react-dates/initialize';
import _ from 'lodash';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdGetApp,
  MdImage,
  MdFullscreen,
  MdFullscreenExit,
  MdVideocam,
  MdVideocamOff,
  MdAddBox,
  MdCamera,
} from 'react-icons/md';
import moment from 'moment'

import { utils } from '../../../utils'
import { cameraActions } from "../../../api/_actions";
import { BasicDropDown } from "../../../_components/BasicDropDown";
import { SpinnerDots } from '../../../_components/SpinnerDots'
import { Spinner } from "../../../_components/Spinner"

const MAX_VIDEO_DURATION = 90 + 2 // 21s -> 90s + 2s loading time

const Pagination = ({ history, onParamsChange }) => {
  history = history || {page: 0, previous: 0, next: 0}
  const { page, previous, next } = history;
  return (
    <nav className="camera-pagination float-right">
      <ul className="pagination">
        <li className={`page-item ${!previous && 'disabled'}`}>
          <a
            className="page-link"
            onClick={() => onParamsChange('page', page - 1)}
          >
            <MdKeyboardArrowLeft size={16}/>
          </a>
        </li>
        <li title="Current page">
          <a className="page-link h-100 bg-transparent">{page}</a>
        </li>
        <li className={`page-item ${!next && 'disabled'}`}>
          <a
            className="page-link"
            onClick={() => onParamsChange('page', page + 1)}
          >
            <MdKeyboardArrowRight size={16}/>
          </a>
        </li>
      </ul>
    </nav>
  )
};

const LimitSize = ({ onParamsChange }) => {
  const onSelect = (value) => {onParamsChange('limit', value)};
  const data = [10, 25, 50];
  return (
    <BasicDropDown 
      data={data}
      onSelect={onSelect}
    />
  );
};

const ImageList = ({images, onFullScreenClick}) => {
  if (images && images.length) {
    return (
    <table className="table table-hover">
      <tbody>
        {images.map(image => {
          return (
            <tr key={image.timestamp}>
              <td className="thumbnail">
                <img src={image.href} className="img-fluid" alt="Snapshot" onClick={onFullScreenClick.bind(this, image)}/>
              </td>
              <td>
                {utils.getImageDateTime(image.timestamp)}
                {(image.size) && 
                  <small className="text-secondary ml-1" title="Size">~ {utils.readableKB(image.size)}</small>
                }
              </td>
              <td className="download">
                <a href={image.href} target="_blank" className="btn btn-link">
                  <MdGetApp size={20} />
                </a>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>)
  } else {
    return (
      <div className="camera-empty-text my-4">
        <MdImage size={20} color="gray" className="mr-1"/>
        No images in the last 24 hours <i className="text-muted">click "+"" button to create one.</i>
      </div>
    )
  }
};

class MainImage extends Component {

  constructor(props) {
    super(props);

    this.imgRef = React.createRef()

    this.toggleModalImage = this.toggleModalImage.bind(this)
    this.startStopVideo = this.startStopVideo.bind(this)
    this.startVideo = this.startVideo.bind(this)
    this.stopVideo = this.stopVideo.bind(this)
    this.openFullScreen = this.openFullScreen.bind(this)
    this.onFullScreenChange = this.onFullScreenChange.bind(this)
    this.onDatesChange = this.onDatesChange.bind(this)    
    this.onFocusChange = this.onFocusChange.bind(this)

    this.state = {
      timer: null,
      isVideoPlaying: false,
      focusedInput: null,
      startDate: null,
      endDate: null,
      modalItem: null,
      modalImageOn: false,
      takingSnapshot: false,
      fullscreenImageIsOn: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps === undefined) {
      return false
    }

    if (this.props.history !== prevProps.history) {
      if (_.get(this.state, "modalItem.mainImage")) {
        this.setState({
          modalItem: Object.assign({}, (_.get(this.props, "history.images") || [])[0], {mainImage: true}) // change First image
        })
      }
    }
  }
  componentDidMount() {
    document.addEventListener('fullscreenchange', this.onFullScreenChange, false);
  }
  componentWillUnmount() {
    document.removeEventListener('fullscreenchange', this.onFullScreenChange, false);
  }

  onFullScreenChange() {
    this.setState({isFullScreenOn: document[utils.getBrowserFullscreenElementProp()] !== null})
  }
  showFullScreenModal(e) {
    if (e) {  
      this.setState({
        modalItem: e,
        modalImageOn: true,
      })
    }
  }
  toggleModalImage() {
    this.setState({modalImageOn: !this.state.modalImageOn})
  }
  startStopVideo() {
    if (this.state.isVideoPlaying) {
      this.stopVideo()
    } else {
      this.startVideo()
    }
  }
  startVideo() {
    let timer = 0;

    this.interval = setInterval(() => {
      ++timer;
      this.setState({ timer, isVideoPlaying: true });

      if( timer >= MAX_VIDEO_DURATION) {
        this.stopVideo();
        clearInterval(this.interval);
        this.interval = null
      }
    }, 1000);

  }
  stopVideo() {
    clearInterval(this.interval);
    this.interval = null
    this.setState({ timer: null, isVideoPlaying: false });
  }
  openFullScreen() {
    this.imgRef.current && 
    this.imgRef.current.requestFullscreen &&
    this.imgRef.current.requestFullscreen()
  }
  onDatesChange({ startDate, endDate }) {
    const { onParamsChange } = this.props;
    let newRange = {}

    if (startDate) { newRange['start_date'] = startDate.format('YYYY-MM-DD')}
    if (endDate) { newRange['end_date'] = endDate.format("YYYY-MM-DD")}

    this.setState({ startDate, endDate },
      () => onParamsChange(newRange)
    );
  }
  onFocusChange(focusedInput) {
    this.setState({ focusedInput });
  }

  render() {
    const { history, display, onParamsChange, selectedDisplay, onAddSnapshot, takingSnapshot, onTakeVideoSnapshot, onDownloadSnapshots, downloadingSnapshots, user } = this.props;
    const { focusedInput, startDate, endDate } = this.state;
    const images = _.get(history, 'images');
    const { timer } = this.state;
    const adminRole = (_.get(this.props,"user.payload.role") === "admin")
    const canUpdate = user && user.permissions && user.permissions.canUpdate(utils.Resources.displaySnapshots)

    const timerPercentage = `${timer * 100 / MAX_VIDEO_DURATION}%`;
    const timerCountdown = `${MAX_VIDEO_DURATION - timer} seconds`;
    const displayFullName = display && (_.get(display, "ProjectName", "") + " # " + _.get(display, "DisplayName", ""))
    const hasSnapshots = images && (images.length > 0)
    const mainImg = hasSnapshots ? Object.assign({},images[0],{mainImage: true}) : {href: utils.CAMERA_OFFLINE}

    const expandButton = <React.Fragment>
       <button   type="button"
                  className={"btn btn-dark"}
                  onClick={this.state.modalImageOn ? this.toggleModalImage : this.showFullScreenModal.bind(this, mainImg)} 
                  title={(this.state.modalImageOn) ? "Collapse" :"Expand"}>
          {(this.state.modalImageOn)
              ? <MdFullscreenExit size={20} />
              : <MdFullscreen size={20} />
          }
        </button>
        {hasSnapshots && 
          <div className="btn-group" role="group">
            <a href={hasSnapshots ? images[0].href : ""} target="_blank" className="btn btn-dark" title="Download snapshot">
              <MdGetApp size={20}/>
            </a>
          </div>}
    </React.Fragment>
  

    const addSnapshotButton = canUpdate && (
                                <button type="button"
                                          className={"btn btn-dark"}
                                          onClick={onAddSnapshot} 
                                          title={"Create a new snapshot"}>
                                  {(takingSnapshot)
                                      ? Spinner(takingSnapshot)
                                      : <MdAddBox size={20}/>
                                  }
                                </button>)

    const takeVideoSnapshot =   <label className={(this.state.isVideoPlaying ? "" : "op-60")}>
                                  <button type="button"
                                            className={"btn btn-dark " + (this.state.isVideoPlaying ? "" : "disabled")}
                                            onClick={onTakeVideoSnapshot} 
                                            title={"Take a snapshot from the live stream"}
                                            disabled={this.state.isVideoPlaying ? false : true}>
                                    <MdCamera size={20}/>
                                  </button>
                                </label>

    const zipSnapshots =    <button   type="button"
                                      className={"btn btn-dark ml-2"}
                                      onClick={onDownloadSnapshots}
                                      title="Download snapshots as zip file">
                              {(downloadingSnapshots)
                                  ? <SpinnerDots loading={downloadingSnapshots} /> 
                                  : <MdGetApp size={20} />
                              }
                            </button>

    const videoButton = <button type="button" className="btn btn-dark" onClick={this.startStopVideo} title="View live streaming">
                              {this.state.isVideoPlaying 
                                ? <MdVideocamOff size={20}/>
                                : <MdVideocam size={20}/>
                              }
                            </button>

    const fullScreenButton = <Button 
                              className="btn btn-dark" 
                              title="Full screen"
                              onClick={this.openFullScreen}
                            >
                              <i className={"fa fa-lg mx-1 " + (this.state.isFullScreen ? "fa-compress": "fa-expand")}></i>
                            </Button>;

    const closeButton = <div>
                          {takeVideoSnapshot}
                          {videoButton}
                          {expandButton}
                        </div>

    const modalImage = 
      <Modal isOpen={this.state.modalImageOn} toggle={this.toggleModalImage} className="camera-full-screen">
        <ModalHeader toggle={this.toggleModalImage} close={closeButton} className="flex-parent">
          <a href={"/status/"+selectedDisplay} className={"no-wrap"} title="Project name">{displayFullName}</a>
          {_.get(this.state,"modalItem.timestamp") && 
            <div className="d-inline-flex">
              <small className="text-secondary ml-3" title="Timestamp">{utils.getImageDateTime(this.state.modalItem.timestamp)}</small>
              <small className="text-secondary ml-1" title="Size">~ {utils.readableBytes(this.state.modalItem.size)}</small>
            </div>}
        </ModalHeader>
        <ModalBody>
          {!this.state.isVideoPlaying && 
            <img id="img_preview" src={_.get(this.state, "modalItem.href")} onError={utils.showNoImage} alt="Billboard snapshot" width="67%" onClick={this.toggleModalImage} />
          }
          {this.state.isVideoPlaying && this.state.modalImageOn &&
            <img
              id="img_preview" 
              crossOrigin="anonymous"
              className="camera-video"
              src={`https://api.mrialerts.com/camera/video/${selectedDisplay}`}
              alt="Webcam video"
            />
          }
          {this.state.isVideoPlaying && this.state.modalImageOn &&
            <React.Fragment>
              <div className="text-warning small op-80 my-1"><b>WARNING:</b> Using the live streaming will increase the data usage.</div>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{width: timerPercentage}}
                >
                  {timerCountdown}
                </div>
              </div>
            </React.Fragment>
          }
        </ModalBody>
      </Modal>
   


    return (
      <div className="camera ">
        {/* Main Image */}
        <div className="camera-main-image camera-card-image" ref={this.imgRef}>
          {displayFullName && <div className="project-name">{displayFullName}</div>}
          {mainImg && mainImg.timestamp && 
            <div className="date-time">
              <small className="" title="Timestamp">{utils.getImageDateTime(mainImg.timestamp)}</small>
              <small className="ml-1" title="Size">~ {utils.readableBytes(mainImg.size)}</small>
            </div>}          

          {!this.state.isVideoPlaying ? (
            (hasSnapshots 
              ? <img src={mainImg.href} className={"img-fluid" + (this.state.isFullScreenOn ? " h-100" : "")}         alt="Webcam snapshot" onClick={this.showFullScreenModal.bind(this, mainImg)}/>
              : <img src={mainImg.href} className={"img-fluid no-img" + (this.state.isFullscreenOn ? " h-100" : "")}  alt="Webcam snapshot"/>
            )
            
          ):""}
          {this.state.isVideoPlaying && !this.state.modalImageOn ? (
            <img
              className="camera-video"
              src={`https://api.mrialerts.com/camera/video/${selectedDisplay}`}
              alt="Webcam video"
            />
          ) : ""}
        </div>
        {this.state.isVideoPlaying && !this.state.modalImageOn ? (
          <React.Fragment>
            <div className="text-warning text-center small op-80 my-1"><b>WARNING:</b> Using the live streaming will increase the data usage.</div>
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{width: timerPercentage}}
              >
                {timerCountdown}
              </div>
            </div>
          </React.Fragment>
        ) : ""}
        {/* Main Image - Actions */}
        <div className="camera-actions">
          <div className="camera-date-filter ml-0">
            <DateRangePicker
              onDatesChange={this.onDatesChange}
              onFocusChange={this.onFocusChange}
              focusedInput={focusedInput}
              startDate={startDate}
              endDate={endDate}
              startDateId="camera-filter-start-date"
              endDateId="camera-filter-end-date"
              isOutsideRange={(day)=> {
                if (moment.isMoment(day)) {
                  const minDate = moment().subtract(31, 'days')
                  const maxDate = moment().add(1, 'days')
                  return !day.isBetween(minDate, maxDate)
                } else {
                  return true
                }
              }}
              minDate={moment().subtract(31, 'days')}
              maxDate={moment().add(1, 'days')}
              enableOutsideDays={false}
              showClearDates
              showDefaultInputIcon
              small
            />
          </div>

          <div
            className="btn-group float-right"
            role="group"
            data-toggle="modal"
            data-target="#full-screen-preview"
          >
            {addSnapshotButton}
            {videoButton}
            {hasSnapshots && expandButton}
            {hasSnapshots && fullScreenButton}

          </div>
        </div>
        
        {modalImage}

        <ImageList images={images} onFullScreenClick={this.showFullScreenModal.bind(this)}/>

        {/* Image history pagination */}
        <div className="row">
          <div className="col-sm-1">
            <LimitSize onParamsChange={onParamsChange}/>
          </div>
          <div className="col-sm-5">
            {adminRole && zipSnapshots}
          </div>
          <div className="col-sm-6">
            <Pagination history={history} onParamsChange={onParamsChange}/>
          </div>
        </div>
      </div>
    )
  }
};

class PureCameraHistory extends Component {

  constructor(props) {
    super(props);
    this.addSnapshot = this.addSnapshot.bind(this)
    this.takeVideoSnapshot = this.takeVideoSnapshot.bind(this)
    // this.getHistory = this.getHistory.bind(this)
    this.downloadSnapshots = this.downloadSnapshots.bind(this)

    this.state = {
      params: { page: 1, limit: 10 },
      takingSnapshot: false,
      downloadingSnapshots: false,
    }
  }

  componentDidMount() {
    this.interval = setInterval(this.getHistory.bind(this), 60000);
    this.getHistory();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.interval = null
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedDisplay } = this.props;
    const { params } = this.state;
    const displayChanged = selectedDisplay !== prevProps.selectedDisplay;
    const paramsChanged = !_.isEqual(params, prevState.params);

    if (displayChanged || paramsChanged) {
      this.getHistory();
    }
    if (displayChanged) {
      this.setState({
        history: {
          page: 1
        }})
    }
  }

  getHistory() {
    const { selectedDisplay } = this.props;
    const { params } = this.state;
    if (selectedDisplay) {
      this.props.dispatch(cameraActions.getHistory(selectedDisplay, params))
      .catch(reason => console.warn("# getHistory error: ", reason));
    }
  }
  downloadSnapshots() {
    const { selectedDisplay } = this.props;
    if (selectedDisplay) {
      const params = Object.assign({}, this.state.params, {zip: true}); // zip all images
      this.setState({downloadingSnapshots: true},
        () => {
          this.props.dispatch(cameraActions.getHistoryZip(selectedDisplay, params))
          .then(blob => {
            const display_id = selectedDisplay || ""
            var fileName = `${display_id}_pg${params.page}_snaps.zip`;
            var link = document.createElement('a');
            var url = window.URL.createObjectURL(blob);
            link.href = url
            link.download = fileName;
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            link.remove()
            window.URL.revokeObjectURL(url);
          })
          .catch(reason => console.warn("# downloadSnapshots error: ", reason))
          .finally(() => {
            this.setState({downloadingSnapshots: false})
          })
        })
    }
  }
  addSnapshot() {
    // create New snapshot and Reload
    const { selectedDisplay } = this.props;
    this.setState({
        takingSnapshot: true
      }, () => {
        this.props.dispatch(cameraActions.newSnapshot(selectedDisplay))
        .catch(reason => console.log(reason))
        .finally(() => {
          this.setState({takingSnapshot: false})
        });
    })
  }

  takeVideoSnapshot() {
    utils.takeVideoSnapshot('img_preview')
  }

  handleParamChange(param, value) {
    const { params } = this.state;
    let newVal = {}; 
    if (typeof param === "string") {
      newVal[param] = value
    } else if (typeof param === "object") {
      newVal=param
    }
    this.setState({
      params: {
        ...params,
        page: 1,
        ...newVal
      }
    })
  }

  render() {
    const { error } = this.props
    return (
      <div className="animated fadeIn h-100 w-100">
        <Card className="w-100 mb-0">
          <CardHeader title="">
            <i className="fa fa-align-justify" />
            Camera History {error ? <small className="text-danger ml-2"><b>Error: </b><small>{"" + error}</small></small> : ""}
          </CardHeader>
          <CardBody className="h-100">
            <MainImage
              {...this.props}
              onParamsChange={this.handleParamChange.bind(this)}
              onAddSnapshot={this.addSnapshot}
              takingSnapshot={this.state.takingSnapshot}
              onTakeVideoSnapshot={this.takeVideoSnapshot}
              onDownloadSnapshots={this.downloadSnapshots}
              downloadingSnapshots={this.state.downloadingSnapshots}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { selectedDisplay, display } = state.display;
  const { history, error } = state.camera;
  const { user } = state.authentication
  return {
    selectedDisplay, display, history, error, user
  };

}

export const CameraHistory = connect(mapStateToProps)(PureCameraHistory);
